import { isEmpty, cloneDeep, isArray, intersection } from 'lodash';
import { DateTime } from 'luxon';
import {
  JobFilters,
  TimestampFilterentry,
  TimestampFilterentryOperator,
} from '@/clients/ops';
import { Columns } from './model';
import { reactive } from 'vue';
import { SimplifiedState } from '@/clients/model';

export interface StoredTableState {
  filters?: JobFilters;
  orderBy?: string[];
  offset?: number;
  limit?: number;
  visibleColumns?: Columns[];
  dateModified?: string;
}

export interface StoredTableStateMap {
  // save off table state keyed by the context we were given as a prop
  [key: string]: StoredTableState;
}

export interface CustomFilterSet {
  name: string;
  dateModified: string;
}

export type DateFilter =
  | 'createdTimestamp'
  | 'modifiedTimestamp'
  | 'timelogActualStart'
  | 'timelogActualEnd'
  | 'timelogEstimateStart'
  | 'timelogEstimateEnd';

export const stateFilterOptions = [
  { label: 'In Error', value: SimplifiedState.RUNNING_WITH_ERROR },
  {
    label: 'Assistance Needed',
    value: SimplifiedState.RUNNING_NEED_ASSISTANCE,
  },
  {
    label: 'Assistance In Progress',
    value: SimplifiedState.RUNNING_WITH_ASSISTANCE,
  },
  { label: 'On Hold', value: SimplifiedState.ON_HOLD },
  { label: 'Paused', value: SimplifiedState.PAUSED },
  { label: 'Running', value: SimplifiedState.RUNNING },
  { label: 'Scheduled', value: SimplifiedState.INITIALIZED },
  { label: 'Pending', value: SimplifiedState.CREATED },
  { label: 'Completed', value: SimplifiedState.FINISHED },
  { label: 'Canceled', value: SimplifiedState.CANCELLED },
];

interface StateInterface {
  filters: JobFilters;
  orderBy: string[];
  offset: number;
  limit: number;
  visibleColumns: Columns[];
  filterableColumns?: Columns[];
  autoUpdateTable: boolean;
  savedFilters: CustomFilterSet[];
}

const defaultFilterableColumns = [
  Columns.CREATED,
  Columns.CREATED_BY,
  Columns.END,
  Columns.END_ESTIMATE,
  Columns.LAB,
  Columns.MODIFIED,
  Columns.NAME,
  Columns.START,
  Columns.START_ESTIMATE,
  Columns.STATE,
  Columns.WORKFLOW,
];

/**
 * Centralized store to keep track of all the various filters we can set on our paginated jobs query
 * In addition, automatically saves changes to localStorage.
 */
export default class FiltersController {
  private state: StateInterface;
  private filterSaveContext: string;
  private LOCAL_STORAGE_TABLE_SETTINGS_KEY = 'jobsTableSettings-4';
  private LOCAL_STORAGE_CUSTOM_SAVE_KEY = 'savedFilterSets';
  private defaultFilters: JobFilters;
  private defaultOrderBy: string[];

  /**
   * Create a new controller. You must create a new controller to go along with every table
   * @param saveContext the key under which we save (to localStorage) the current filter set
   * @param initialFilters default/starting filters if there are any
   * @param initialOrderBy default/starting orderBy
   * @param visibleColumns default/starting visible columns
   */
  constructor(
    saveContext: string,
    initialFilters: JobFilters,
    initialOrderBy: string[],
    visibleColumns: Columns[],
    filterableColumns?: Columns[]
  ) {
    this.filterSaveContext = saveContext;
    this.defaultFilters = cloneDeep(initialFilters);
    this.defaultOrderBy = [...initialOrderBy];
    this.state = reactive({
      filters: initialFilters,
      orderBy: initialOrderBy,
      offset: 0,
      limit: 20,
      visibleColumns,
      filterableColumns: filterableColumns || defaultFilterableColumns,
      autoUpdateTable: true,
      savedFilters: this.getSavedFilterSets(),
    });
    this.restoreState(initialFilters);
  }

  /**
   * Get the last table settings from localStorage
   * @returns the last table settings
   * @throws error if for some reason the stored settings are invalid JSON
   */
  private getStoredTableState(): StoredTableStateMap {
    return JSON.parse(
      localStorage.getItem(this.LOCAL_STORAGE_TABLE_SETTINGS_KEY) || '{}'
    );
  }

  /**
   * Get all the saved filter sets
   * @returns All the saved filters
   */
  private getSavedFilters(): StoredTableStateMap {
    return JSON.parse(
      localStorage.getItem(this.LOCAL_STORAGE_CUSTOM_SAVE_KEY) || '{}'
    );
  }

  /**
   * Get all the saved filter set names/dates
   * @returns an array of saved filters (tuple of name, date)
   */
  private getSavedFilterSets(): CustomFilterSet[] {
    const savedFilters = this.getSavedFilters();
    return Object.keys(savedFilters).map((name) => ({
      name,
      dateModified: savedFilters[name].dateModified || '',
    }));
  }

  /**
   * Restore the last known table state from localStorage
   * @param initialFilters default/starting filters
   */
  private restoreState(initialFilters: JobFilters) {
    const storedTableStateMap: StoredTableStateMap = this.getStoredTableState();
    const storedTableState = storedTableStateMap[this.filterSaveContext];
    if (!isEmpty(storedTableState)) {
      this.state.filters =
        storedTableState.filters || cloneDeep(initialFilters);
      if (Number.isFinite(storedTableState.offset)) {
        // @ts-ignore
        this.state.offset = storedTableState.offset;
      }
      if (Number.isFinite(storedTableState.limit)) {
        // @ts-ignore
        this.state.limit = storedTableState.limit;
      }
      if (storedTableState.orderBy) {
        this.state.orderBy = storedTableState.orderBy;
      }
      if (storedTableState.visibleColumns) {
        this.state.visibleColumns = storedTableState.visibleColumns;
      }
    }
  }

  /**
   * Restores all filters and orderBy to their default state
   */
  public clearAllFilters() {
    this.state.filters = cloneDeep(this.defaultFilters);
    this.state.offset = 0;
    this.state.limit = 20;
    this.state.orderBy = this.defaultOrderBy;
  }

  /**
   * Save the current filter set to local storage
   * @param name the key to save the filter under
   */
  public saveCustomFilterSet(name: string) {
    const savedFilters = this.getSavedFilters();
    const dateModified = new Date().toISOString();
    savedFilters[name] = {
      filters: this.jobFilters,
      dateModified,
    };
    localStorage.setItem(
      this.LOCAL_STORAGE_CUSTOM_SAVE_KEY,
      JSON.stringify(savedFilters)
    );
    this.state.savedFilters.push({ name, dateModified });
  }

  /**
   * Load a saved filter set
   * @param name the key the filter was saved under
   */
  public loadCustomFilterSet(name: string) {
    const savedFilters = this.getSavedFilters();
    if (savedFilters[name]?.filters && !isEmpty(savedFilters[name].filters)) {
      // @ts-ignore
      this.state.filters = savedFilters[name].filters;
      this.storeTableSettings();
    }
  }

  /**
   * Delete a saved filter set
   * @param name the key the filter was saved under
   */
  public deleteCustomFilterSet(name: string) {
    const savedFilters = this.getSavedFilters();
    delete savedFilters[name];
    localStorage.setItem(
      this.LOCAL_STORAGE_CUSTOM_SAVE_KEY,
      JSON.stringify(savedFilters)
    );
    const idx = this.savedFilterSets.findIndex((s) => s.name === name);
    if (idx >= 0) {
      this.state.savedFilters.splice(idx, 1);
    }
  }

  /**
   * Stores the current table filters/offset/limit/etc... to localStorage so that
   * a refresh won't clear the existing filter set.
   */
  private storeTableSettings() {
    const storedTableStateMap: StoredTableStateMap = this.getStoredTableState();
    const storedTableState: StoredTableState = {
      filters: this.jobFilters,
      orderBy: this.state.orderBy,
      offset: this.state.offset,
      limit: this.state.limit,
      visibleColumns: this.state.visibleColumns,
    };
    const key = this.filterSaveContext;
    storedTableStateMap[key] = storedTableState;
    localStorage.setItem(
      this.LOCAL_STORAGE_TABLE_SETTINGS_KEY,
      JSON.stringify(storedTableStateMap)
    );
  }

  /**
   * Converts an date range (array of dates) to the structure that the jobs-service API expects
   * @param dateRange the array of dates [start, end]
   * @returns TimestampFilterentry array that represents the given date range
   */
  private dateRangeToFilter(
    dateRange?: Date[]
  ): TimestampFilterentry[] | undefined {
    if (dateRange?.length) {
      // the date picker will return 00:00:00 on the end day, we want to include the end day
      // so add 23:59:59 and add in all the hours preceding midnight on the next day
      const endRange = DateTime.fromJSDate(dateRange[1])
        .plus({
          hours: 23,
          minutes: 59,
          seconds: 59,
          milliseconds: 999,
        })
        .toUTC();
      return [
        {
          datetime: dateRange[0].toISOString(),
          operator: TimestampFilterentryOperator.GE,
        },
        {
          datetime: endRange.toISO() || '',
          operator: TimestampFilterentryOperator.LE,
        },
      ];
    }
    return undefined;
  }

  /**
   * Converts the date filters given to the API to a standard array of Date objects
   * @param filter the date filter entries
   * @returns array of dates in the form [start, end]
   */
  private filterToDateRange(filter?: TimestampFilterentry[]): Date[] {
    if (filter) {
      return filter.map((d) => new Date(d.datetime));
    }
    return [];
  }

  /**
   * Given which date filter the client wants - gets a date range
   * @param filter @see DateFilter
   * @returns the date range in the form of an array of Date objects [start, end]
   */
  public getTimestampFilter(filter: DateFilter): Date[] {
    return this.filterToDateRange(this.state.filters[filter]);
  }

  /**
   * Given which date filter the client wants to set - sets a date range
   * @param filter @see DateFilter
   * @param timestamps the date range to set in the filters
   */
  public setTimeStampFilter(filter: DateFilter, timestamps: Date[]) {
    this.state.filters[filter] = this.dateRangeToFilter(timestamps);
    this.storeTableSettings();
  }

  /**
   * Helper method to clear out a column from the orderBy list (used when changing the set of visible columns)
   * @param col the column to remove
   * @returns true if the column was part of the orderBy list, otherwise false. Clients can use this to determine if a requery is needed
   */
  public removeOrderBy(col: Columns): boolean {
    const idx = this.state.orderBy.findIndex((o) => o === col);
    if (idx >= 0) {
      this.state.orderBy.splice(idx - 1, 2);
      return true;
    }
    return false;
  }

  /**
   * If a filter is not set, the API expects that field to be undefined, not "empty".
   * Before returning our filter set to the client, clear out anything that isn't set.
   * @param filters the job filter set
   * @returns the job filter set with any "empty" fields set to undefined.
   */
  private deleteUnsetFields(filters: JobFilters): JobFilters {
    const filterCopy = cloneDeep(filters);
    for (const key of Object.keys(filterCopy)) {
      if (
        !filterCopy[key] ||
        (isArray(filterCopy[key]) && filterCopy[key].length === 0)
      ) {
        delete filterCopy[key];
      }
    }
    return filterCopy;
  }

  public switchLab(labId: string, context: string) {
    this.state.filters.labId = labId;
    this.filterSaveContext = context;
  }

  /**
   * ACCESSORS
   */

  public isColumnFilterable(column: Columns): boolean {
    if (column === Columns.ACTION) {
      return false;
    }
    if (this.filterableColumns && this.filterableColumns.length > 0) {
      return this.filterableColumns.includes(column);
    }
    return true;
  }

  public get jobFilters(): JobFilters {
    return this.deleteUnsetFields(this.state.filters);
  }

  public get saveContext(): string {
    return this.filterSaveContext;
  }

  public get savedFilterSets(): CustomFilterSet[] {
    return this.state.savedFilters;
  }

  public get nameFilter(): string | undefined {
    return this.state.filters.name;
  }
  public set nameFilter(newName: string | undefined) {
    this.state.filters.name = newName;
    this.storeTableSettings();
  }

  public get nameFilters(): string[] | undefined {
    return this.state.filters.names;
  }
  public set nameFilters(names: string[] | undefined) {
    this.state.filters.names = names;
    this.storeTableSettings();
  }

  public get stateFilter(): SimplifiedState[] | undefined {
    return this.state.filters.states;
  }
  public set stateFilter(states: SimplifiedState[] | undefined) {
    this.state.filters.states = states;
    this.storeTableSettings();
  }

  public get createdBy(): string | undefined {
    return this.state.filters.createdBy;
  }
  public set createdBy(cb: string | undefined) {
    this.state.filters.createdBy = cb;
    this.storeTableSettings();
  }

  public get createdByList(): string[] | undefined {
    return this.state.filters.createdByList;
  }
  public set createdByList(cb: string[] | undefined) {
    this.state.filters.createdByList = cb;
    this.storeTableSettings();
  }

  public get modifiedBy(): string | undefined {
    return this.state.filters.modifiedBy;
  }
  public set modifiedBy(mb: string | undefined) {
    this.state.filters.modifiedBy = mb;
    this.storeTableSettings();
  }

  public get labId(): string | undefined {
    return this.state.filters.labId;
  }
  public set labId(id: string | undefined) {
    this.state.filters.labId = id;
    this.storeTableSettings();
  }

  public get labIds(): string[] | undefined {
    return this.state.filters.labIds;
  }
  public set labIds(ids: string[] | undefined) {
    this.state.filters.labIds = ids;
    this.storeTableSettings();
  }

  public get workflowId(): string | undefined {
    return this.state.filters.workflowId;
  }
  public set workflowId(id: string | undefined) {
    this.state.filters.workflowId = id;
    this.storeTableSettings();
  }

  public get workflowIds(): string[] | undefined {
    return this.state.filters.workflowIds;
  }
  public set workflowIds(ids: string[] | undefined) {
    this.state.filters.workflowIds = ids;
    this.storeTableSettings();
  }

  public get numSetFilters(): number {
    let filterCount = 0;
    if (this.createdByList && this.createdByList.length > 0) {
      filterCount += 1;
    }
    if (
      this.getTimestampFilter('createdTimestamp') &&
      this.getTimestampFilter('createdTimestamp').length > 0
    ) {
      filterCount += 1;
    }
    if (
      this.getTimestampFilter('modifiedTimestamp') &&
      this.getTimestampFilter('modifiedTimestamp').length > 0
    ) {
      filterCount += 1;
    }
    if (
      this.getTimestampFilter('timelogActualStart') &&
      this.getTimestampFilter('timelogActualStart').length > 0
    ) {
      filterCount += 1;
    }
    if (
      this.getTimestampFilter('timelogEstimateStart') &&
      this.getTimestampFilter('timelogEstimateStart').length > 0
    ) {
      filterCount += 1;
    }
    if (
      this.getTimestampFilter('timelogActualEnd') &&
      this.getTimestampFilter('timelogActualEnd').length > 0
    ) {
      filterCount += 1;
    }
    if (
      this.getTimestampFilter('timelogEstimateEnd') &&
      this.getTimestampFilter('timelogEstimateEnd').length > 0
    ) {
      filterCount += 1;
    }
    if (this.nameFilters && this.nameFilters.length > 0) {
      filterCount += 1;
    }
    if (this.stateFilter && this.stateFilter.length > 0) {
      filterCount += 1;
    }
    if (this.workflowIds && this.workflowIds.length > 0) {
      filterCount += 1;
    }
    return filterCount;
  }

  public get visibleColumns(): Columns[] {
    return this.state.visibleColumns;
  }
  public set visibleColumns(columns: Columns[]) {
    this.state.visibleColumns.splice(
      0,
      this.state.visibleColumns.length,
      ...columns
    );
    this.storeTableSettings();
  }

  public get filterableColumns(): Columns[] | undefined {
    return intersection(
      this.state.filterableColumns,
      this.state.visibleColumns
    );
  }

  public get orderBy(): string[] {
    return this.state.orderBy;
  }
  public set orderBy(order: string[]) {
    this.state.orderBy.splice(0, this.state.orderBy.length, ...order);
    this.storeTableSettings();
  }

  public get offset(): number {
    return this.state.offset;
  }
  public set offset(os: number) {
    this.state.offset = os;
    this.storeTableSettings();
  }

  public get limit(): number {
    return this.state.limit;
  }
  public set limit(lim: number) {
    this.state.limit = lim;
    this.storeTableSettings();
  }

  public get autoUpdateTable(): boolean {
    return this.state.autoUpdateTable;
  }
  public set autoUpdateTable(update: boolean) {
    this.state.autoUpdateTable = update;
  }
}
