
import { defineComponent, onUnmounted, onMounted } from 'vue';
import { OutputUnit } from '@hyperjump/json-schema/draft-07';
import { EntityType } from '@/clients/config';
import UIController from '@/clients/ui';
import ConfigEditor from '@/components/GenericForm/ConfigEditor.vue';
import { SimpleResponse } from '@/components/GenericForm/types';

// interface Props {
//   jobId: string;
//   action: (parameters) => void;
// }

export default defineComponent({
  props: {
    jobId: {
      type: String,
      required: true,
    },
    action: {
      type: Function,
      required: true,
    },
  },
  components: {
    ConfigEditor,
  },
  setup(props) {
    function handleUpdateValues(newConfig: SimpleResponse) {
      if (props.action) {
        props.action({ updateType: 'responseData', data: newConfig });
      }
    }

    function handleValidationError(event: OutputUnit) {
      if (props.action) {
        props.action({ updateType: 'responseError', data: event });
      }
    }

    onMounted(() => (UIController.Instance.displayCenterDrawer = true));
    onUnmounted(() => (UIController.Instance.displayCenterDrawer = false));

    return {
      handleUpdateValues,
      handleValidationError,
      EntityType,
    };
  },
});
