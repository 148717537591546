import { DateTime } from 'luxon';
import unescape from 'validator/es/lib/unescape';
import {
  Common,
  nullCommon,
  Timelog,
  nullTimelog,
  IO,
  SimplifiedState,
} from '../model';
import { Action } from '../action';
import { ActionType, nullAction } from '../action/model';
import {
  RequestJSONSchema,
  SimpleResponse,
} from '@/components/GenericForm/types';
import { createSchema, morphism } from 'morphism';

export enum CommandState {
  PAUSE,
  RESUME,
  CANCEL,
  START,
  STOP,
  RETRY_JOB,
  RETRY_ACTION,
  UNSCHEDULE,
  DELETE,
  DEBUG,
}

// TODO:  Move to something more scheduler specific?
export enum ScheduleTactic {
  DEFAULT = 'DEFAULT',
  APPEND = 'APPEND',
  AFTER_JOB = 'AFTER_JOB',
  AFTER_ACTION = 'AFTER_ACTION',
  TIME = 'TIME',
  NOW = 'NOW',
}

export interface Schedule {
  tactic: ScheduleTactic;
  jobIds: string[];
}

export interface SolutionInfo {
  solutionFound: boolean;
}

export interface MissingAbility {
  actorId: string;
  abilityName: string;
}

export interface MissingActorAbility {
  missingActorAbility: MissingAbility;
}

export interface ScheduleError {
  errorType: MissingActorAbility;
}

export interface ActionSchedules {
  actionId: string;
  endTimestamp: string;
  startTimestamp: string;
  msDuration: number;
  scheduledActor: ScheduledActor;
  actorId: string;
  locked?: boolean;
  common?: Common;
  start: DateTime;
  end: DateTime;
  duration?: number;
  name?: string;
}

export interface JobSchedules {
  jobId: string;
  endTimestamp: string;
  startTimestamp: string;
  msDuration: number;
  scheduledActor: ScheduledActor;
}
export interface ScheduleResponse {
  scheduleId: string;
  generatedAPrioriScheduleId?: string;
  actionSchedules?: ActionSchedules[];
  jobSchedules?: JobSchedules[];
  solutionInfo?: SolutionInfo;
  errors?: ScheduleError[];
}

export interface PreviewJobResponse {
  scheduleResponses: ScheduleResponse[];
  generatedAPrioriScheduleIdOfBestSolution: string;
}

export interface Note {
  userId: string;
  createdTimestamp: string;
  id: string;
  text: string;
}

export interface File {
  id: string;
  jobId: string;
  labId: string;
  sourceTimestamp: string;
  sourceFilename: string;
  relativeDownloadPath: string;
  name?: string;
  resultId?: string;
  actionId?: string;
  contentType: string;
  description?: string;
  fileSize?: string;
}

export enum TimestampFilterentryOperator {
  EQ = 'EQ',
  GE = 'GE',
  GT = 'GT',
  LE = 'LE',
  LT = 'LT',
}

export interface TimestampFilterentry {
  datetime: string;
  operator: TimestampFilterentryOperator;
}

export interface JobFilters {
  name?: string;
  names?: string[];
  actionId?: string;
  createdTimestamp?: TimestampFilterentry[];
  createdBy?: string;
  createdByList?: string[];
  modifiedTimestamp?: TimestampFilterentry[];
  modifiedBy?: string;
  modifiedByList?: string[];
  labId?: string;
  labIds?: string[];
  jobIds?: string[];
  states?: SimplifiedState[];
  timelogActualStart?: TimestampFilterentry[];
  timelogActualEnd?: TimestampFilterentry[];
  timelogEstimateStart?: TimestampFilterentry[];
  timelogEstimateEnd?: TimestampFilterentry[];
  workflowId?: string;
  workflowIds?: string[];
}

export interface JobTableData {
  jobs: Job[];
  limit: number;
  offset: number;
  totalCount: number;
}

export interface GanttActivity {
  name: string;
  start: string;
  end: string;
  state: string;
  actionId: string;
  actionType: ActionType;
}
export interface GanttAnnotation {
  type: JobEventType;
  timestamp: string;
}
export interface GanttJob {
  name: string;
  id: string;
  workflowId: string;
  start: string;
  end: string;
  activities: GanttActivity[];
  annotations: GanttAnnotation[];
}

export interface LastFailedActionInfo {
  actionId: string;
  canSkip: boolean;
  canRetry: boolean;
  isAnonymous: boolean;
}

export interface Job {
  id: string;
  name: string;
  parentId: string;
  actionId: string;
  workflowId: string;
  labId: string;
  state: SimplifiedState;
  orderid?: string;
  numSamples?: number;
  inputs?: IO[];
  outputs?: IO[];
  timelog?: Timelog;
  schedule?: Schedule;
  actions?: Action[];
  files?: File[];
  children?: Job[];
  notes?: Note[];
  common: Common;
  action?: Action; // workflow
  spawnedBy?: string;
  spawnedJobs?: string[];
  focusedAction?: Action; // performance tweak, keep the "currently running" or "in error" action handy for an O(1) search
  lastFailedAction?: LastFailedActionInfo;
  events: JobEvent[];
  totalEventCount?: number;
  currentEventOffset?: number;
}

export enum JobEventType {
  UNKNOWN = 'UNKNOWN',
  JOB_CREATED = 'JOB_CREATED',
  JOB_PARAMETERS_APPLIED = 'JOB_PARAMETERS_APPLIED',
  JOB_STARTED = 'JOB_STARTED',
  JOB_STATE_CHANGED = 'JOB_STATE_CHANGED',
  JOB_ERRORED = 'JOB_ERRORED',
  JOB_COMPLETED = 'JOB_COMPLETED',
  JOB_CANCEL_REQUESTED = 'JOB_CANCEL_REQUESTED',
  JOB_CANCELLED = 'JOB_CANCELLED',
  JOB_CANCEL_FAILED = 'JOB_CANCEL_FAILED',
  JOB_FILE_CAPTURED = 'JOB_FILE_CAPTURED',
  JOB_PAUSE_REQUESTED = 'JOB_PAUSE_REQUESTED',
  JOB_PAUSED = 'JOB_PAUSED',
  JOB_PAUSE_FAILED = 'JOB_PAUSE_FAILED',
  JOB_RESUME_REQUESTED = 'JOB_RESUME_REQUESTED',
  JOB_RESUMED = 'JOB_RESUMED',
  JOB_RESUME_FAILED = 'JOB_RESUME_FAILED',
  JOB_SPAWNED_CHILD = 'JOB_SPAWNED_CHILD',
  JOB_NOTE_ADDED = 'JOB_NOTE_ADDED',
  JOB_NOTE_UPDATED = 'JOB_NOTE_UPDATED',
  JOB_BATCHED_INTO = 'JOB_BATCHED_INTO',
  JOB_SCHEDULE_REQUESTED = 'JOB_SCHEDULE_REQUESTED',
  JOB_SCHEDULE_COMPLETED = 'JOB_SCHEDULE_COMPLETED',
  JOB_SCHEDULE_FAILED = 'JOB_SCHEDULE_FAILED',
  JOB_UNSCHEDULE_REQUESTED = 'JOB_UNSCHEDULE_REQUESTED',
  JOB_UNSCHEDULE_COMPLETED = 'JOB_UNSCHEDULE_COMPLETED',
  JOB_UNSCHEDULE_FAILED = 'JOB_UNSCHEDULE_FAILED',
  JOB_CONFIG_CHANGED = 'JOB_CONFIG_CHANGED',
  JOB_OUTPUTS_CREATED = 'JOB_OUTPUTS_CREATED',
  JOB_BLOCKED = 'JOB_BLOCKED',
  ACTION_CREATED = 'ACTION_CREATED',
  ACTION_STARTED = 'ACTION_STARTED',
  ACTION_AT_LABMANAGER = 'ACTION_AT_LABMANAGER',
  ACTION_AT_ACTOR = 'ACTION_AT_ACTOR',
  ACTION_STATE_CHANGED = 'ACTION_STATE_CHANGED',
  ACTION_ERRORED = 'ACTION_ERRORED',
  ACTION_BLOCKED = 'ACTION_BLOCKED',
  ACTION_COMPLETED = 'ACTION_COMPLETED',
  ACTION_CANCEL_REQUESTED = 'ACTION_CANCEL_REQUESTED',
  ACTION_CANCELLED = 'ACTION_CANCELLED',
  ACTION_CANCEL_FAILED = 'ACTION_CANCEL_FAILED',
  ACTION_PAUSE_REQUESTED = 'ACTION_PAUSE_REQUESTED',
  ACTION_PAUSED = 'ACTION_PAUSED',
  ACTION_PAUSE_FAILED = 'ACTION_PAUSE_FAILED',
  ACTION_RESUME_REQUESTED = 'ACTION_RESUME_REQUESTED',
  ACTION_RESUMED = 'ACTION_RESUMED',
  ACTION_RESUME_FAILED = 'ACTION_RESUME_FAILED',
  ACTION_RETRY_REQUESTED = 'ACTION_RETRY_REQUESTED',
  ACTION_RETRIED = 'ACTION_RETRIED',
  ACTION_RETRY_FAILED = 'ACTION_RETRY_FAILED',
  ACTION_SIGNATURE_CREATED = 'ACTION_SIGNATURE_CREATED',
  ACTION_SIGN_OFF_RULES_SATISFIED = 'ACTION_SIGN_OFF_RULES_SATISFIED',
  ACTION_MANUAL_OVERRIDE_REQUESTED = 'ACTION_MANUAL_OVERRIDE_REQUESTED',
  ACTION_MANUAL_OVERRIDE = 'ACTION_MANUAL_OVERRIDE',
  ACTION_MANUAL_OVERRIDE_FAILED = 'ACTION_MANUAL_OVERRIDE_FAILED',
  ACTION_FILE_CAPTURED = 'ACTION_FILE_CAPTURED',
  ACTION_FILE_CAPTURE_INITIATED = 'ACTION_FILE_CAPTURE_INITIATED',
  ACTION_REVOKED = 'ACTION_REVOKED',
  ACTION_UNREVOKED = 'ACTION_UNREVOKED',
  ASSISTANT_SKIPPED = 'ASSISTANT_SKIPPED',
  ASSISTANT_STEP_SKIPPED = 'ASSISTANT_STEP_SKIPPED',
  ASSISTANT_TASK_SKIPPED = 'ASSISTANT_TASK_SKIPPED',
  ASSISTANCE_NEEDED = 'ASSISTANCE_NEEDED',
  GENERAL_ERROR = 'GENERAL_ERROR',
  ORG_CONFIG_CHANGED = 'ORG_CONFIG_CHANGED',
  LAB_CONFIG_CHANGED = 'LAB_CONFIG_CHANGED',
  ADAPTER_CONFIG_CHANGED = 'ADAPTER_CONFIG_CHANGED',
  WORKFLOW_CONFIG_CHANGED = 'WORKFLOW_CONFIG_CHANGED',
  ADAPTER_EVENT = 'ADAPTER_EVENT',
  HEALTH_EVENT = 'HEALTH_EVENT',
  PLACEHOLDER = 'PLACEHOLDER', // For entries in the timeline that have no corresponding event (e.g. - Action headers)
}

export const JobEventTypeFilter = [
  JobEventType.JOB_CREATED,
  JobEventType.JOB_CANCELLED,
  JobEventType.JOB_CANCEL_REQUESTED,
  JobEventType.JOB_COMPLETED,
  JobEventType.JOB_FILE_CAPTURED,
  JobEventType.JOB_PAUSE_REQUESTED,
  JobEventType.JOB_PAUSED,
  JobEventType.JOB_RESUMED,
  JobEventType.JOB_RESUME_REQUESTED,
  JobEventType.JOB_SPAWNED_CHILD,
  JobEventType.JOB_STARTED,
  JobEventType.JOB_PARAMETERS_APPLIED,
  JobEventType.JOB_OUTPUTS_CREATED,
  JobEventType.ACTION_AT_ACTOR,
  JobEventType.ACTION_CANCELLED,
  JobEventType.ACTION_COMPLETED,
  JobEventType.ACTION_FILE_CAPTURE_INITIATED,
  JobEventType.ACTION_MANUAL_OVERRIDE,
  JobEventType.ACTION_PAUSE_REQUESTED,
  JobEventType.ACTION_PAUSED,
  JobEventType.ACTION_RESUMED,
  JobEventType.ACTION_REVOKED,
  JobEventType.ACTION_RETRY_REQUESTED,
  JobEventType.ACTION_SIGNATURE_CREATED,
  JobEventType.ACTION_SIGN_OFF_RULES_SATISFIED,
  JobEventType.ACTION_STARTED,
  JobEventType.ASSISTANT_SKIPPED,
  JobEventType.ASSISTANT_STEP_SKIPPED,
  JobEventType.ASSISTANT_TASK_SKIPPED,
  JobEventType.ASSISTANCE_NEEDED,
  JobEventType.ADAPTER_EVENT,
  JobEventType.HEALTH_EVENT,
  JobEventType.GENERAL_ERROR,
];

export const JobEventErrorsTypeFilter = [
  JobEventType.GENERAL_ERROR,
  JobEventType.ACTION_AT_ACTOR,
  JobEventType.ACTION_COMPLETED,
  JobEventType.ACTION_FILE_CAPTURE_INITIATED,
  JobEventType.ACTION_MANUAL_OVERRIDE,
  JobEventType.ACTION_PAUSED,
  JobEventType.ACTION_RESUMED,
  JobEventType.ACTION_RETRY_REQUESTED,
  JobEventType.ACTION_RETRIED,
  JobEventType.ACTION_RETRY_FAILED,
  JobEventType.ACTION_STARTED,
  JobEventType.ADAPTER_EVENT,
  JobEventType.HEALTH_EVENT,
];

export enum EventSourceType {
  EVENT_SOURCE_TYPE_UNKNOWN = 'EVENT_SOURCE_TYPE_UNKNOWN',
  EVENT_SOURCE_TYPE_JOB_EXECUTOR = 'EVENT_SOURCE_TYPE_JOB_EXECUTOR',
  EVENT_SOURCE_TYPE_JOB_SCHEDULER = 'EVENT_SOURCE_TYPE_JOB_SCHEDULER',
  EVENT_SOURCE_TYPE_WORKFLOW_SERVICE = 'EVENT_SOURCE_TYPE_WORKFLOW_SERVICE',
  EVENT_SOURCE_TYPE_EXPRESSION_SERVICE = 'EVENT_SOURCE_TYPE_EXPRESSION_SERVICE',
  EVENT_SOURCE_TYPE_LABMANAGER = 'EVENT_SOURCE_TYPE_LABMANAGER',
  EVENT_SOURCE_TYPE_USER = 'EVENT_SOURCE_TYPE_USER',
  EVENT_SOURCE_TYPE_JOBAPI = 'EVENT_SOURCE_TYPE_JOBAPI',
  EVENT_SOURCE_TYPE_ACTOR = 'EVENT_SOURCE_TYPE_ACTOR',
  EVENT_SOURCE_TYPE_ACTION = 'EVENT_SOURCE_TYPE_ACTION',
  EVENT_SOURCE_TYPE_ASSISTANT = 'EVENT_SOURCE_TYPE_ASSISTANT',
  EVENT_SOURCE_TYPE_STATE_SERVICE = 'EVENT_SOURCE_TYPE_STATE_SERVICE',
  EVENT_SOURCE_TYPE_JOB_SERVICE = 'EVENT_SOURCE_TYPE_JOB_SERVICE',
  EVENT_SOURCE_TYPE_BATCHING_SERVICE = 'EVENT_SOURCE_TYPE_BATCHING_SERVICE',
  EVENT_SOURCE_TYPE_CONFIG_SERVICE = 'EVENT_SOURCE_TYPE_CONFIG_SERVICE',
  EVENT_SOURCE_TYPE_RESULTS_SERVICE = 'EVENT_SOURCE_TYPE_RESULTS_SERVICE',
  EVENT_SOURCE_TYPE_PUBLIC_ERRORS = 'EVENT_SOURCE_TYPE_PUBLIC_ERRORS',
}

export const EventSourceTypeFilter = [
  EventSourceType.EVENT_SOURCE_TYPE_ASSISTANT,
  EventSourceType.EVENT_SOURCE_TYPE_USER,
  EventSourceType.EVENT_SOURCE_TYPE_JOB_EXECUTOR,
  EventSourceType.EVENT_SOURCE_TYPE_JOB_SERVICE,
];

export interface JobEventBackend {
  eventType: JobEventType;
  labId: string;
  jobId: string;
  actionId: string;
  actionType: ActionType;
  actionName: string;
  redisTimestamp: string;
  initiatorType: EventSourceType;
  initiatorId?: string;
  senderType?: string;
  senderId?: string;
  message?: string;
  parameterValues?: SimpleResponse;
  children?: JobEvent[];
  details?: string;
  inputParameters?: string;
  outputParameters?: string;
}

export interface LoopIndex {
  index: number;
  loopName: string;
}

export interface JobEvent {
  type: JobEventType;
  labId: string;
  jobId: string;
  actionId: string;
  redisTimestamp: string; // keep an opaque version of this timestamp to use as a cursor
  timestamp: Date;
  initiatorType: EventSourceType;
  details: SimpleResponse;
  initiatorId?: string;
  senderType?: string;
  senderId?: string;
  message?: string;
  parameterValues?: SimpleResponse;
  loopId?: string;
  generatedLoopId?: string;
  loopIndices?: LoopIndex[];
  children?: JobEvent[];
  actionName?: string;
  actionType?: ActionType;
  inputsSchema?: RequestJSONSchema;
  inputValues?: SimpleResponse;
  outputsSchema?: RequestJSONSchema;
  outputValues?: SimpleResponse;
}

export interface JobEventQueryResponse {
  jobEvents: JobEventBackend[];
  limit: number;
  offset: number;
  totalCount: number;
}

export interface JobEventResponse {
  jobEvents: JobEvent[];
  limit: number;
  offset: number;
  totalCount: number;
}

const mapJobEventFromSchema = createSchema<JobEvent, JobEventBackend>({
  type: 'eventType',
  labId: 'labId',
  jobId: 'jobId',
  actionId: 'actionId',
  actionType: 'actionType',
  timestamp: (input) => new Date(input.redisTimestamp),
  redisTimestamp: 'redisTimestamp',
  initiatorType: 'initiatorType',
  initiatorId: 'initiatorId',
  senderType: 'senderType',
  senderId: 'senderId',
  details: (input) => {
    try {
      return JSON.parse(input.details || '');
    } catch (_) {
      return {};
    }
  },
  actionName: 'actionName',
  message: (input) => {
    try {
      const details = JSON.parse(input.details || '');
      return unescape(details?.publicError?.errorText || '');
    } catch (_) {
      return undefined;
    }
  },
  loopId: (input) => {
    try {
      const details = JSON.parse(input.details || '');
      return details?.common?.loopId || '';
    } catch (_) {
      return undefined;
    }
  },
  loopIndices: (input) => {
    try {
      const details = JSON.parse(input.details || '');
      return details?.common?.loopIndices || [];
    } catch (_) {
      return [];
    }
  },
  generatedLoopId: (input) => {
    try {
      const details = JSON.parse(input.details || '');
      const indices: LoopIndex[] = details?.common?.loopIndices || [];
      return indices.map((i) => i.index.toString()).join('.');
    } catch (_) {
      return '';
    }
  },
  inputsSchema: (input) => {
    try {
      const paramInfo = JSON.parse(input.inputParameters || '');
      if (!paramInfo.parameterSchema) {
        throw '';
      }
      return paramInfo.parameterSchema;
    } catch (_) {
      return undefined;
    }
  },
  inputValues: (input) => {
    try {
      const paramInfo = JSON.parse(input.inputParameters || '');
      if (!paramInfo.parameterValues) {
        throw '';
      }
      return paramInfo.parameterValues;
    } catch (_) {
      return undefined;
    }
  },
  outputsSchema: (input) => {
    try {
      const paramInfo = JSON.parse(input.outputParameters || '');
      if (!paramInfo.parameterSchema) {
        throw '';
      }
      return paramInfo.parameterSchema;
    } catch (_) {
      return undefined;
    }
  },
  outputValues: (input) => {
    try {
      const paramInfo = JSON.parse(input.outputParameters || '');
      if (!paramInfo.parameterValues) {
        throw '';
      }
      return paramInfo.parameterValues;
    } catch (_) {
      return undefined;
    }
  },
});

export function mapFromBackend(source: JobEventBackend[]): JobEvent[] {
  return morphism(mapJobEventFromSchema, source);
}

export interface UserJobDocumentResponse {
  document: string;
}

export function nullJob(): Job {
  return {
    id: '',
    name: '',
    parentId: '',
    actionId: '',
    workflowId: '',
    labId: '',
    state: SimplifiedState.UNKNOWN,
    numSamples: 0,
    inputs: [],
    outputs: [],
    timelog: nullTimelog(),
    schedule: { tactic: ScheduleTactic.DEFAULT, jobIds: [] },
    actions: [],
    children: [],
    notes: [],
    files: [],
    common: nullCommon(),
    action: nullAction(),
    events: [],
  };
}

export enum ActionCommand {
  START = 'START',
  STOP = 'STOP',
  PAUSE = 'PAUSE',
  RESUME = 'RESUME',
  CANCEL = 'CANCEL',
}

export interface ActionCommandMessage {
  actionId: string;
  command: ActionCommand;
}

export interface AgentInfo {
  name: string;
  instance?: string;
  version?: string;
  healthInfo?: Record<string, string>[];
}

export interface ConnectionInfo {
  client: AgentInfo;
  server: AgentInfo;
  scope: string;
}

export interface Lab {
  id: string;
  name: string;
  description: string;
  state: SimplifiedState;
  location: string;
  label: string;
  version: string;
  jobs: Job[];
  common: Common;
  thumbnailUrl: string;
  adapterIds?: string[];
  connections?: ConnectionInfo[];
  bannedConnections?: string[];
  deploymentConfiguration?: string;
  numInitializedJobs?: number;
  numOnHoldJobs?: number;
  numRunningJobs?: number;
  numRunningNeedAssistanceJobs?: number;
  numRunningWithErrorJobs?: number;
  numRunningWithAssistanceJobs?: number;
  numPausedJobs?: number;
}

export function nullLab(): Lab {
  return {
    id: '',
    name: '',
    description: '',
    state: SimplifiedState.UNKNOWN,
    location: '',
    label: '',
    version: '',
    jobs: [],
    common: nullCommon(),
    thumbnailUrl: '',
    adapterIds: [],
    connections: [],
    bannedConnections: [],
    numInitializedJobs: 0,
    numOnHoldJobs: 0,
    numRunningJobs: 0,
    numRunningNeedAssistanceJobs: 0,
    numRunningWithErrorJobs: 0,
    numPausedJobs: 0,
    numRunningWithAssistanceJobs: 0,
  };
}

export interface JobState {
  id: string;
  job: Job;
  assistants?: Action[];
}

export interface JobValidationResponse {
  success: boolean;
  errors: string[];
}

export enum PublicErrorSeverity {
  UNKNOWN = 'UNKNOWN',
  CRITICAL = 'CRITICAL',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export interface PublicError {
  labId: string;
  jobId?: string;
  actionId?: string;
  workflowId?: string;
  category: string;
  errorId: string;
  errorCode: number;
  title?: string;
  errorText: string;
  lineNumber: number;
  severity: PublicErrorSeverity;
  timestamp: string;
  sourceFile: string;
  sourceModule: string;
}

interface ScheduledActor {
  actorId: string;
  executorId: string;
}

export enum SchedulingType {
  SCHEDULE_TYPE_SOLVE_GLOBAL = 'SCHEDULE_TYPE_SOLVE_GLOBAL',
  SCHEDULE_TYPE_SOLVE_LOCAL = 'SCHEDULE_TYPE_SOLVE_LOCAL',
}

export interface HeartbeatSubscription {
  subscriptionId: string;
  heartbeatInterval?: NodeJS.Timer;
  unsubscribeFn: () => void;
}
