
import { defineComponent, computed, shallowRef, ComputedRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import UserController from '@/clients/users';
import OpsController from '@/clients/ops';
import ConfigController from '@/clients/config';
import Profile from './components/Profile/Profile.vue';
import Account from './components/Profile/Account.vue';
import Tokens from './components/Profile/Tokens.vue';
import TOTP from './components/Profile/TOTP.vue';
import Members from './components/Members/Members.vue';
import Workflows from './components/Expert/Workflows.vue';
import Services from './components/Expert/Services.vue';
import LabOperations from './components/LabOperations/LabOperations.vue';
import TreeView, { TreeData } from '@/components/TreeView.vue';
import Toasts from '@/components/notifications/Toasts.vue';
import OrgConfig from './components/Org/OrgConfig.vue';

export default defineComponent({
  components: {
    Profile,
    Members,
    Workflows,
    TreeView,
    Toasts,
    OrgConfig,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    OpsController.Instance.dispatchGetLabsForSettings().then((labs) => {
      labs.map((l) => ConfigController.Instance.dispatchGetLabConfig(l.id));
    });
    ConfigController.Instance.dispatchGetOrgConfig();
    const orgConfig = computed(() => ConfigController.Instance.orgConfig);
    const labs = computed(() =>
      OpsController.Instance.labs.map((lab) => {
        const mappedLab: TreeData = {
          id: lab.id,
          label: lab.name,
          component: shallowRef(LabOperations),
        };
        return mappedLab;
      })
    );

    UserController.Instance.dispatchGetCurrentUser();
    const userName = computed(
      () => `${UserController.Instance.currentUser?.name} (Me)`
    );

    const treeData: ComputedRef<TreeData[]> = computed(() =>
      [
        {
          id: 'user',
          label: userName.value,
          items: [
            {
              id: 'account',
              label: 'My Account',
              component: shallowRef(Account),
            },
            {
              id: 'tokens',
              label: 'API Keys',
              component: shallowRef(Tokens),
            },
            {
              id: 'totp',
              label: 'One-Time Password',
              component: shallowRef(TOTP),
            },
          ],
        },
        {
          id: 'organization',
          label: 'Organization',
          items: [
            {
              id: 'members',
              label: 'Members',
              component: shallowRef(Members),
            },
            {
              id: 'configuration',
              label: 'Configuration',
              component: shallowRef(OrgConfig),
            },
          ],
        },
        {
          id: 'instance',
          label: window.location.host,
          artificialOnly: true,
          items: [
            {
              id: 'workflows',
              label: 'Workflows',
              component: shallowRef(Workflows),
            },
            {
              id: 'services',
              label: 'Services',
              component: shallowRef(Services),
            },
          ],
        },
        {
          id: 'labs',
          label: 'Labs',
          items: labs.value,
        },
      ].filter((data) => {
        if (data.artificialOnly) {
          return UserController.Instance.isActingAsArtificialUser;
        }
        return true;
      })
    );

    const menus = computed(() =>
      treeData.value.map((data) => data.items?.flat() || []).flat()
    );

    const selectedMenuItem = computed<TreeData>(() => {
      const newMenu =
        menus.value.find((c) => c.id === route.query.menu) ?? menus.value[0];
      return newMenu;
    });

    const handleSelectMenuItem = (menuId: string) => {
      router.push({ ...route, query: { menu: menuId } });
    };

    return {
      labs,
      handleSelectMenuItem,
      orgConfig,
      treeData,
      selectedMenuItem,
    };
  },
});
