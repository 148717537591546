
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const copyText = () =>
      navigator.clipboard.writeText('support@artificial.com');
    return {
      copyText,
    };
  },
});
