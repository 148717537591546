
import { defineComponent, ref, computed, watch, Ref } from 'vue';
import { useRouter } from 'vue-router';
import moment from 'moment';
import { formatDateTime, isTextOverflowed } from '@/pages/utils';
import OpsController, { Job } from '@/clients/ops';
import UIController from '@/clients/ui';
import UserController from '@/clients/users';
import ActionController from '@/clients/action';
import { ActionType, nullAction } from '@/clients/action/model';
import { EntityType } from '@/clients/config';
import { CommandState } from '@/clients/ops/model';
import { SimplifiedState } from '@/clients/model';
import { LAB_ROUTE } from '@/router/names';
import { nullJob } from '@/clients/ops/model';
import ActionBanner from './ActionBanner.vue';
import JobActivityTimeline from './JobDetails/Activity/JobActivityTimeline.vue';
import DetailsSummary from './JobDetails/Summary/DetailsSummary.vue';
import JobActivityLegacy from './JobDetails/Summary/JobActivityLegacy.vue';
import JobResources from './JobDetails/Results/JobResources.vue';
import ErrorsList from './JobDetails/Errors/ErrorsList.vue';
import Notes from './JobDetails/Notes.vue';
// @ts-ignore
import StateTag, { StateTagSize } from '@/components/StateTag.vue';
import JobDetailActions from './JobDetails/JobDetailActions.vue';
import ActionBar from '@/components/ActionBar.vue';
import RetryActionDialog, {
  RetryActionData,
} from './JobCards/RetryActionDialog.vue';
import SkipActionDialog from './JobCards/SkipActionDialog.vue';
import ConfirmCancelDialog from '@/components/ConfirmCancelDialog.vue';
import { getDate, getTime } from '../utils';

export default defineComponent({
  components: {
    ActionBanner,
    DetailsSummary,
    JobResources,
    ErrorsList,
    Notes,
    StateTag,
    JobDetailActions,
    ActionBar,
    RetryActionDialog,
    SkipActionDialog,
    ConfirmCancelDialog,
    JobActivityTimeline,
    JobActivityLegacy,
  },
  props: {
    jobId: {
      required: true,
      type: String,
    },
    labId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const showLegacy = ref(false);
    const legacyOrJobEventsFinalized = ref(false);

    const isHovered = ref(false);

    const expertMode = computed(() => UIController.Instance.expert);

    const copyJobId = () => {
      navigator.clipboard.writeText(props.jobId);
    };

    const fetchInitialData = () => {
      showLegacy.value = false;
      legacyOrJobEventsFinalized.value = false;
      OpsController.Instance.dispatchGetJobDetailsData(props.jobId).then(
        async (j) => {
          // for assistants, always show the new timeline because they'll never
          // have a valid workflow tree.
          if (!(j.action?.actionType === ActionType.ASSISTANT)) {
            const tree =
              await ActionController.Instance.dispatchGetWorkflowTree(
                j.action?.id || ''
              );
            if (!tree) {
              showLegacy.value = true;
            }
          }
          legacyOrJobEventsFinalized.value = true;
          OpsController.Instance.dispatchGetJobParameterEvents(props.jobId);
        }
      );
    };

    fetchInitialData();

    // These to determine whether or not to show tooltip (only on overflow)
    const jobName: Ref<HTMLElement | null> = ref(null);
    const isJobNameOverflowed = computed(() => isTextOverflowed(jobName.value));

    const close = () => emit('close');
    const job = computed(
      () => OpsController.Instance.getJob(props.jobId) || nullJob()
    );
    const actionState = computed(() => job.value?.action?.state);
    const jobState = computed(() => {
      if (job.value.state === SimplifiedState.PAUSED) {
        return {
          text: job.value.state,
          class: job.value.state,
          state: job.value.state,
        };
      }
      if (job.value.state === SimplifiedState.CANCELLED) {
        return {
          text: 'Canceled',
          class: 'CANCELLED',
          state: job.value.state,
        };
      }
      if (
        actionState.value === SimplifiedState.RUNNING_NEED_ASSISTANCE ||
        job.value.state === SimplifiedState.RUNNING_NEED_ASSISTANCE
      ) {
        return {
          text: 'Assistance Needed',
          class: 'NEEDS-ATTENTION',
          state: SimplifiedState.RUNNING_NEED_ASSISTANCE,
        };
      } else if (
        job.value.state === SimplifiedState.RUNNING_WITH_ASSISTANCE ||
        actionState.value === SimplifiedState.RUNNING_WITH_ASSISTANCE
      ) {
        return {
          text: 'Assistant in Progress',
          class: 'RUNNING',
          state: SimplifiedState.RUNNING,
        };
      } else if (job.value.state === SimplifiedState.INITIALIZED) {
        return {
          text: 'SCHEDULED',
          class: 'SCHEDULED',
          state: job.value.state,
        };
      } else if (job.value.state === SimplifiedState.CREATED) {
        return {
          text: 'PENDING',
          class: 'PENDING',
          state: job.value.state,
        };
      } else if (job.value.state === SimplifiedState.FINISHED) {
        return {
          text: 'Completed',
          class: job.value.state,
          state: job.value.state,
        };
      }
      return {
        text: job.value.state,
        class: job.value.state,
        state: job.value.state,
      };
    });

    const isWorkflow = computed(
      () =>
        job.value?.action?.actionType === ActionType.WORKFLOW ||
        job.value?.action?.actionType === ActionType.RECURRING
    );
    const actions = computed(() => {
      if (isWorkflow.value) {
        return OpsController.Instance.getActionsByJob(job.value.id);
      } else if (job.value.action) {
        // if this job is based on an assistant and not a workflow
        const action = OpsController.Instance.getAssistant(
          job.value?.action?.id
        );
        action.children = action.children?.filter(
          (child) => !child.assistant?.hideStep
        );
        return [action];
      }
      return [];
    });

    const runningAssistant = computed(() => {
      if (isWorkflow.value) {
        return actions.value?.filter(
          (a) => a?.state === SimplifiedState.RUNNING_NEED_ASSISTANCE
        )[0];
      } else if (job.value.state === SimplifiedState.RUNNING_NEED_ASSISTANCE) {
        const actions = OpsController.Instance.getActionsByJob(props.jobId);
        return actions.find(
          (a) => a.state === SimplifiedState.RUNNING_NEED_ASSISTANCE
        );
      }
    });

    const errorAction = computed(() => {
      if (job.value.state !== SimplifiedState.RUNNING_WITH_ERROR) {
        return null;
      } else if (actions.value) {
        const failedActionId = OpsController.Instance.getLastFailedAction(
          job.value.id
        )?.actionId;
        if (failedActionId) {
          return OpsController.Instance.getAction(failedActionId);
        }
        return null;
      }
    });

    const canSkip = computed(() => !!job.value.lastFailedAction?.canSkip);
    const canRetry = computed(() => !!job.value.lastFailedAction?.canRetry);

    const labState = computed(() => {
      return OpsController.Instance.getLabState(job.value.labId);
    });

    const completedPercentage = computed(() => {
      const finished = actions.value?.filter(
        (a) => a?.state === SimplifiedState.FINISHED
      )?.length;
      const total = actions.value?.length;
      if (finished && total) {
        return (finished / total) * 100;
      }
      return 0;
    });

    const currentStep = computed(() => {
      const completed = actions.value?.filter(
        (a) => a?.state === SimplifiedState.FINISHED
      )?.length;
      return completed < actions.value.length
        ? completed + 1
        : actions.value.length;
    });

    const timeRemaining = computed(() => {
      const startTime = moment(job.value?.timelog?.actual?.startTimestamp);
      const endTime = moment(job.value?.timelog?.estimate?.endTimestamp);
      if (startTime && endTime) {
        const diff = endTime.diff(startTime, 'minutes');
        const hours = Math.round(diff / 60);
        const minutes = diff - hours * 60;
        return `${hours}:${minutes}:00 LEFT`;
      }
      return '--:--';
    });

    const isRequest = computed(
      () => job.value?.state === SimplifiedState.CREATED || job.value?.parentId
    );

    const createdTime = computed(() => {
      if (job.value.common?.createdTimestamp) {
        return formatDateTime(job.value?.common.createdTimestamp);
      }
    });
    const scheduledTime = computed(() => {
      if (job.value?.timelog?.estimate?.startTimestamp) {
        return formatDateTime(job.value?.timelog?.estimate?.startTimestamp);
      }
    });
    const endTime = computed(() => {
      if (job.value?.timelog?.actual?.endTimestamp) {
        return formatDateTime(job.value?.timelog?.actual?.endTimestamp);
      }
    });
    const filters = computed(() =>
      isRequest.value
        ? ['DETAILS', 'NOTES']
        : ['ACTIVITY', 'DETAILS', 'RESULTS', 'NOTES']
    );
    const selectedFilter = ref(filters.value[0]);
    const setSelectedFilter = (filter) => {
      OpsController.Instance.dispatchGetJobDetailsData(job.value?.id);
      selectedFilter.value = filter;
    };

    watch(isRequest, () => {
      if (!filters.value.includes(selectedFilter.value)) {
        setSelectedFilter(filters.value[0]);
      }

      if (
        !isRequest.value &&
        selectedFilter.value !== 'ACTIVITY' &&
        !isHovered.value
      ) {
        setSelectedFilter('ACTIVITY');
      }
    });

    const isDevMode = computed(() => UIController.Instance.expert);
    const commandError = ref('');
    const showCommandError = ref(false);
    const hideCommandError = () => {
      showCommandError.value = false;
      commandError.value = '';
    };
    const showCancelConfirmDialog = ref(false);

    // eslint-disable-next-line no-undef
    let popoverTimeout: NodeJS.Timeout;
    const executeJobCommand = async (command: CommandState | string) => {
      try {
        clearTimeout(popoverTimeout);
        commandError.value = '';
        showCommandError.value = false;
        if (command === 'SCHEDULE') {
          emit('schedule-job', job.value, false);
          emit('close');
        } else if (command === CommandState.UNSCHEDULE) {
          await OpsController.Instance.dispatchUnscheduleJobs([props.jobId]);
        } else if (command === CommandState.DEBUG) {
          const operation =
            job.value.state === SimplifiedState.CREATED
              ? 'launchJob'
              : 'attachJob';
          window.open(
            `vscode://artificial.artificial-debug-extension/${operation}?jobId=${props.jobId}`
          );
        } else if (command === CommandState.CANCEL) {
          showCancelConfirmDialog.value = true;
        } else {
          await OpsController.Instance.dispatchJobCommand(
            props.jobId,
            command as CommandState
          );
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        commandError.value = e;
        showCommandError.value = true;
        popoverTimeout = setTimeout(hideCommandError, 3000);
      }
    };

    const updateSelectedJob = (job: Job) => {
      setSelectedFilter(filters.value[0]);
      emit('updateSelectedJob', job);
    };

    const numJobErrors = computed(
      () =>
        OpsController.Instance.getErrorsByLab(props.labId).filter(
          (e) => e.jobId === props.jobId
        ).length
    );
    const viewJobErrors = () => {
      emit('viewErrors', job.value.name);
      close();
    };

    const goToAssistant = (id?: string) => {
      const assistantId = id || runningAssistant.value?.id;
      if (assistantId) {
        router.push({
          name: LAB_ROUTE,
          query: { labId: props.labId, assistantId },
        });
      }
    };

    const skipAssistant = (id?: string) => {
      const assistantId = id || runningAssistant.value?.id || null;
      if (runningAssistant.value && assistantId) {
        OpsController.Instance.dispatchFinishAssistant(
          assistantId,
          UserController.Instance.currentUser.id,
          new Date().toISOString()
        );
      }
    };

    const retryActionDialog = ref(false);
    const retryActionData: Ref<RetryActionData> = ref({
      action: null,
      job: null,
      error: null,
      labId: '',
    });
    const handleRetryAction = () => {
      const errorAction = OpsController.Instance.getActionsByJob(
        props.jobId
      ).find(
        (a) =>
          a?.state === SimplifiedState.RUNNING_WITH_ERROR ||
          a?.state === SimplifiedState.ERROR
      );
      if (errorAction) {
        retryActionData.value = {
          action: errorAction,
          job: job.value,
          labId: props.labId,
          error: null,
        };
        retryActionDialog.value = true;
      } else {
        retryActionData.value = {
          action: null,
          job: job.value,
          labId: props.labId,
          error:
            'The job is in an error state but no action could be found in an error state.',
        };
      }
      retryActionDialog.value = true;
    };

    const skipActionDialog = ref(false);
    const actionToSkip = ref(nullAction());
    const handleSkipAction = () => {
      const errorAction = OpsController.Instance.getFocusedAction(props.jobId);
      actionToSkip.value = errorAction || nullAction();
      skipActionDialog.value = true;
    };

    const openErrorLog = () => {
      UIController.Instance.displayErrorLog =
        !UIController.Instance.displayErrorLog;
    };

    const focusedAction = computed(() =>
      OpsController.Instance.getFocusedAction(props.jobId)
    );

    watch(
      () => props.jobId,
      (newId, oldId) => {
        if (newId !== oldId) {
          fetchInitialData();
        }
      }
    );

    watch(
      () => job.value.state,
      (newState) => {
        if (newState === SimplifiedState.FINISHED) {
          OpsController.Instance.dispatchGetJobDetailsData(props.jobId);
        }
      }
    );

    return {
      expertMode,
      copyJobId,
      isHovered,
      jobName,
      isJobNameOverflowed,
      getDate,
      getTime,
      formatDateTime,
      isWorkflow,
      SimplifiedState,
      CommandState,
      job,
      actions,
      completedPercentage,
      close,
      jobState,
      runningAssistant,
      errorAction,
      labState,
      currentStep,
      timeRemaining,
      isRequest,
      createdTime,
      scheduledTime,
      endTime,
      filters,
      selectedFilter,
      setSelectedFilter,
      showCancelConfirmDialog,
      executeJobCommand,
      commandError,
      showCommandError,
      hideCommandError,
      updateSelectedJob,
      numJobErrors,
      viewJobErrors,
      retryActionDialog,
      retryActionData,
      handleRetryAction,
      canSkip,
      canRetry,
      skipActionDialog,
      handleSkipAction,
      actionToSkip,
      isDevMode,
      goToAssistant,
      skipAssistant,
      openErrorLog,
      focusedAction,
      showLegacy,
      legacyOrJobEventsFinalized,
      EntityType,
      StateTagSize,
    };
  },
});
