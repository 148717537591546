import { isEqual, cloneDeep } from 'lodash';
import { SimpleResponse } from '@/components/GenericForm/types';
import { reactive } from 'vue';
import ActionController from '@/clients/action';
import OpsController from '@/clients/ops';
import {
  EntityConfiguration,
  EntityType,
  ServiceVersion,
  nullConfig,
} from './model';
import * as Service from './service.apollo';
interface Configs {
  [key: string]: EntityConfiguration;
}

interface StateInterface {
  configs: Configs;
  serviceVersions: ServiceVersion[];
}

export default class Controller {
  private static instance: Controller;
  private state: StateInterface;

  private constructor() {
    /*
     * STATE
     */
    this.state = reactive({
      configs: {},
      serviceVersions: [],
    });
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  /**
   * get the current org configuration
   * @returns Promise containing the current org configuration
   */
  public async dispatchGetOrgConfig(): Promise<EntityConfiguration> {
    const config = await Service.getOrgConfig();
    if (config.entityId) {
      this.state.configs['org'] = config;
    }
    return config;
  }

  /**
   * get configuration for the given lab
   * @param labId the ID of the lab
   * @returns Promise containing the lab's configuration
   */
  public async dispatchGetLabConfig(
    labId: string
  ): Promise<EntityConfiguration> {
    const config = await Service.getLabConfig(labId);
    if (config.entityId) {
      this.state.configs[labId] = config;
    }
    return config;
  }

  /**
   * get configuration for the given workflow
   * @param workflowId the ID of the workflow
   * @returns Promise containing the workflow's configuration
   */
  public async dispatchGetWorkflowConfig(
    workflowId: string
  ): Promise<EntityConfiguration> {
    const workflow = ActionController.Instance.getAction(workflowId);
    if (workflow.constraint?.labId) {
      const config = await Service.getWorkflowConfig(
        workflowId,
        workflow.constraint.labId
      );
      if (config.entityId) {
        this.state.configs[workflowId] = config;
      }
      return config;
    }
    return nullConfig();
  }

  /**
   * Get the configuration of a job
   * @param jobId the ID of the job
   * @returns Promise containing the job's configuration
   */
  public async dispatchGetJobConfig(
    jobId: string
  ): Promise<EntityConfiguration> {
    const job = OpsController.Instance.getJob(jobId);
    if (job?.labId) {
      const config = await Service.getJobConfig(jobId, job.labId);
      if (config.entityId) {
        this.state.configs[jobId] = config;
      }
      return config;
    }
    return nullConfig();
  }

  /**
   * Get an adapter's configuration
   * @param labId the ID of the lab the adapter is deployed to
   * @param adapterId the ID of the adapter
   * @returns Promise containing the adapter's configuration
   */
  public async dispatchGetAdapterConfig(
    labId: string,
    adapterId: string
  ): Promise<EntityConfiguration> {
    const existingConfig = this.getAdapterConfig(adapterId);
    const config = await Service.getAdapterConfig(labId, adapterId);
    // only replace the config if it has changed, otherwise
    // the polling behavior will trigger a re-render and the user will never
    // be able to save their changes to the config
    if (
      config.entityId &&
      existingConfig?.configValuesVersion !== config?.configValuesVersion
    ) {
      this.state.configs[adapterId] = config;
    }
    return config;
  }

  /**
   * Get the configuration of a given entity
   * @param configType the type of entity
   * @param id the id of the configured entity
   * @param contextualId if an additional ID is needed, pass it here
   * @returns Promise containing the configuration
   */
  public async dispatchGetConfig(
    configType: EntityType,
    id: string,
    contextualId?: string
  ): Promise<EntityConfiguration> {
    if (configType === EntityType.ENTITY_TYPE_ORG) {
      return this.dispatchGetOrgConfig();
    } else if (configType === EntityType.ENTITY_TYPE_LAB) {
      return this.dispatchGetLabConfig(id);
    } else if (configType === EntityType.ENTITY_TYPE_WORKFLOW) {
      return this.dispatchGetWorkflowConfig(id);
    } else if (configType === EntityType.ENTITY_TYPE_JOB) {
      return this.dispatchGetJobConfig(id);
    } else if (configType === EntityType.ENTITY_TYPE_ADAPTER && contextualId) {
      return this.dispatchGetAdapterConfig(contextualId, id);
    }
    return nullConfig();
  }

  /**
   * Updates values of the org config
   * MUTATION/IDEMPOTENT
   * @param configValues the new values
   */
  public async dispatchUpdateOrgConfig(configValues: SimpleResponse) {
    // we need to holistically deal with copies here or we'll inadvertently trigger reactivity
    const orgConfig = cloneDeep(this.orgConfig);
    if (orgConfig && !isEqual(orgConfig.configValues, configValues)) {
      orgConfig.configValues = configValues;
      const newRev = await Service.updateConfig(orgConfig);
      if (newRev) {
        this.state.configs.org.configValues = cloneDeep(configValues);
        this.state.configs.org.configValuesVersion = newRev;
      }
    }
  }

  /**
   * Updates the values of a lab config
   * MUTATION/IDEMPOTENT
   * @param labId the ID of the lab
   * @param configValues the new values
   */
  public async dispatchUpdateLabConfig(
    labId: string,
    configValues: SimpleResponse
  ) {
    // we need to holistically deal with copies here or we'll inadvertently trigger reactivity
    const labConfig = cloneDeep(this.getLabConfig(labId));
    if (labConfig && !isEqual(labConfig?.configValues, configValues)) {
      labConfig.configValues = configValues;
      const newRev = await Service.updateConfig(labConfig);
      if (newRev) {
        this.state.configs[labId].configValues = cloneDeep(configValues);
        this.state.configs[labId].configValuesVersion = newRev;
      }
    }
  }

  /**
   * Updates the values of a workflow config
   * MUTATION/IDEMPOTENT
   * @param workflowId the ID of the workflow
   * @param configValues the new values
   */
  public async dispatchUpdateWorkflowConfig(
    workflowId: string,
    configValues: SimpleResponse
  ) {
    // we need to holistically deal with copies here or we'll inadvertently trigger reactivity
    const workflowConfig = cloneDeep(this.getWorkflowConfig(workflowId));
    if (
      workflowConfig &&
      !isEqual(workflowConfig?.configValues, configValues)
    ) {
      workflowConfig.configValues = configValues;
      const newRev = await Service.updateConfig(workflowConfig);
      if (newRev) {
        this.state.configs[workflowId].configValues = cloneDeep(configValues);
        this.state.configs[workflowId].configValuesVersion = newRev;
      }
    }
  }

  /**
   * update a job's configuration
   * MUTATION/IDEMPOTENT
   * @param jobId the ID of the job
   * @param configValues the new configuration
   */
  public async dispatchUpdateJobConfig(
    jobId: string,
    configValues: SimpleResponse
  ) {
    // we need to holistically deal with copies here or we'll inadvertently trigger reactivity
    const jobConfig = cloneDeep(this.getJobConfig(jobId));
    if (jobConfig && !isEqual(jobConfig.configValues, configValues)) {
      jobConfig.configValues = configValues;
      const newRev = await Service.updateConfig(jobConfig);
      if (newRev) {
        this.state.configs[jobId].configValues = cloneDeep(configValues);
        this.state.configs[jobId].configValuesVersion = newRev;
      }
    }
  }

  /**
   * update a adapter's configuration
   * MUTATION/IDEMPOTENT
   * @param adapterId the ID of the adapter
   * @param configValues the new configuration
   */
  public async dispatchUpdateAdapterConfig(
    adapterId: string,
    configValues: SimpleResponse
  ) {
    // we need to holistically deal with copies here or we'll inadvertently trigger reactivity
    const adapterConfig = cloneDeep(this.getAdapterConfig(adapterId));
    if (adapterConfig && !isEqual(adapterConfig.configValues, configValues)) {
      adapterConfig.configValues = configValues;
      const newRev = await Service.updateConfig(adapterConfig);
      if (newRev) {
        this.state.configs[adapterId].configValues = cloneDeep(configValues);
        this.state.configs[adapterId].configValuesVersion = newRev;
      }
    }
  }

  /**
   * Update the values of a configuration
   * @param configType the type of configuration
   * @param id the id of the configured entity
   * @param configValues the new values
   */
  public async dispatchUpdateConfig(
    configType: EntityType,
    id: string,
    configValues: SimpleResponse
  ) {
    if (configType === EntityType.ENTITY_TYPE_ORG) {
      this.dispatchUpdateOrgConfig(configValues);
    } else if (configType === EntityType.ENTITY_TYPE_LAB) {
      this.dispatchUpdateLabConfig(id, configValues);
    } else if (configType === EntityType.ENTITY_TYPE_WORKFLOW) {
      this.dispatchUpdateWorkflowConfig(id, configValues);
    } else if (configType === EntityType.ENTITY_TYPE_JOB) {
      this.dispatchUpdateJobConfig(id, configValues);
    } else if (configType === EntityType.ENTITY_TYPE_ADAPTER) {
      this.dispatchUpdateAdapterConfig(id, configValues);
    }
  }

  /**
   * Delete ("hide") an unused adapter configuration
   * @param id the adapter id
   */
  public async dispatchDeleteAdapterConfig(id: string) {
    const config = this.getAdapterConfig(id);
    if (config) {
      const success = await Service.deleteConfig(config);
      if (success) {
        delete this.state.configs[id];
      }
      return success;
    }
    return false;
  }

  public async dispatchGetServiceVersions() {
    const versions = await Service.getServiceVersions();
    this.state.serviceVersions = versions;
  }

  // GETTERS/SETTERS

  public get serviceVersions(): ServiceVersion[] {
    return this.state.serviceVersions;
  }

  public get orgConfig(): EntityConfiguration | undefined {
    return this.state.configs.org;
  }

  public getLabConfig(labId: string): EntityConfiguration | undefined {
    return this.state.configs[labId];
  }

  public getWorkflowConfig(
    workflowId: string
  ): EntityConfiguration | undefined {
    return this.state.configs[workflowId];
  }

  public getJobConfig(jobId: string): EntityConfiguration | undefined {
    return this.state.configs[jobId];
  }

  public getAdapterConfig(adapterId: string): EntityConfiguration | undefined {
    return this.state.configs[adapterId];
  }

  public getConfig(
    configType: EntityType,
    configId: string
  ): EntityConfiguration | undefined {
    if (configType === EntityType.ENTITY_TYPE_ORG) {
      return this.orgConfig;
    }
    return this.state.configs[configId];
  }
}
