
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import AuthController from '@/clients/auth';

export default defineComponent({
  setup() {
    const router = useRouter();
    const user = computed(() => AuthController.Instance.user);
    const loggedIn = computed(() => AuthController.Instance.loggedIn);
    const userName = computed(() => {
      return user.value.name ? user.value.name : user.value.email.split('@')[0];
    });

    const companyName = computed(() => {
      const logoUrl = user.value.companyLogo;
      if (logoUrl.includes('thermofisher')) {
        return 'THERMOFISHER';
      } else if (logoUrl.includes('beam')) {
        return 'BEAM';
      }

      return 'ARTIFICIAL';
    });

    const goToSettings = () => {
      router.push({
        name: 'Settings',
        query: {
          menu: 'account',
        },
      });
    };

    return {
      ...user,
      user,
      companyName,
      userName,
      loggedIn,
      goToSettings,
    };
  },
});
