
import { defineComponent, computed, ref } from 'vue';
import { UserWithRole, UserRoles } from '@/clients/users/model';
import UserController from '@/clients/users/controller';
import RoleSelector from './RoleSelector.vue';
import ArtificialIcon from '@/assets/icons/artificial.svg';

export default defineComponent({
  components: {
    RoleSelector,
  },
  setup() {
    const addMemberDialogVisible = ref(false);
    const userIdToAdd = ref<number>();

    const allUsers = computed<UserWithRole[]>(() =>
      UserController.Instance.users.map((u) => {
        const rolePolicy = getRolePolicy(u.roleIds);
        return {
          ...u,
          role: getRole(rolePolicy),
          rolePolicy,
        };
      })
    );

    const users = computed(() =>
      allUsers.value.filter(
        (u) =>
          u.rolePolicy === UserRoles.ADMIN || u.rolePolicy === UserRoles.MEMBER
      )
    );

    const usersWithoutAccess = computed(() =>
      allUsers.value.filter((u) => u.rolePolicy === null)
    );

    const fallBack = (e) => (e.target.src = ArtificialIcon);

    const getRolePolicy = (roleIds: string[]) => {
      if (roleIds.includes(UserRoles.ADMIN)) {
        return UserRoles.ADMIN;
      } else if (roleIds.includes(UserRoles.MEMBER)) {
        return UserRoles.MEMBER;
      } else {
        return null;
      }
    };

    const getRole = (rolePolicy: UserRoles | null) => {
      switch (rolePolicy) {
        case UserRoles.ADMIN:
          return 'Admin';
        case UserRoles.MEMBER:
          return 'Member';
        default:
          return 'No access';
      }
    };

    const currentUser = computed(() => UserController.Instance.currentUser);
    const isAdmin = computed(() => {
      if (currentUser.value.roleIds.length) {
        return getRolePolicy(currentUser.value.roleIds) === UserRoles.ADMIN;
      }
      return false;
    });

    const handleAddMember = async () => {
      if (!userIdToAdd.value) return;
      await UserController.Instance.dispatchPromoteUserMember(
        userIdToAdd.value
      );
      addMemberDialogVisible.value = false;
      userIdToAdd.value = undefined;
    };

    return {
      UserRoles,
      users,
      usersWithoutAccess,
      currentUser,
      fallBack,
      getRole,
      isAdmin,
      addMemberDialogVisible,
      userIdToAdd,
      handleAddMember,
    };
  },
});
