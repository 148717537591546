
import { isEmpty, some } from 'lodash';
import {
  defineComponent,
  computed,
  ComputedRef,
  ref,
  Ref,
  watch,
  PropType,
  onUnmounted,
} from 'vue';
import { OutputUnit } from '@hyperjump/json-schema/draft-07';
import moment from 'moment';
import ActionController from '@/clients/action';
import ConfigController from '@/clients/config';
import OpsController from '@/clients/ops/controller';
import UserController from '@/clients/users/controller';
import UIController from '@/clients/ui';
import { Job, JobValidationResponse } from '@/clients/ops/model';
import { Action, ActionType } from '@/clients/action/model';
import Wizard, { Step } from '../RequestCreation/Wizard.vue';
import JobDetailsModule from './JobDetailsModule.vue';
import SchedulerModule from './SchedulerModule.vue';
import WorkflowRequest from '../RequestCreation/WorkflowRequest.vue';
import ParameterValueInfo from '../RequestCreation/ParameterValueInfo.vue';
import JobConfig from './JobConfig.vue';
import ReviewAndSubmit from '../ReviewAndSubmit.vue';
import {
  InteractivityHookStage,
  RequestJSONSchema,
  SimpleResponse,
} from '@/components/GenericForm/types';

export default defineComponent({
  components: {
    Wizard,
    JobDetailsModule,
    WorkflowRequest,
    ReviewAndSubmit,
  },
  props: {
    labId: {
      type: String,
      required: true,
    },
    job: {
      type: Object as PropType<Job | null>,
    },
  },
  setup(props, { emit }) {
    // Open the left drawer
    UIController.Instance.displayLeftDrawer = true;

    // These are two very different things. The workflow is definitional and is fetched/tracked
    // by the ActionController. The top level action is an instance of that workflow and is
    // fetched/tracked by the OpsController
    const selectedWorkflow: Ref<Action | null> = ref(null);
    const selectedTopLevelAction: Ref<Action | null> = ref(
      props.job?.action || null
    );

    if (props.job?.workflowId) {
      selectedWorkflow.value = ActionController.Instance.getAction(
        props.job.workflowId
      );
    }
    // the unfiltered schema
    const workflowSchema = computed(() => {
      if (selectedTopLevelAction.value) {
        return OpsController.Instance.getActionSchema(
          selectedTopLevelAction.value.id
        );
      }
    });

    // the schema filtered for only postBatch fields
    const postBacthWorkflowSchema = computed(() => {
      if (selectedTopLevelAction.value) {
        return OpsController.Instance.getActionSchema(
          selectedTopLevelAction.value.id,
          InteractivityHookStage.INTERACTIVITY_HOOK_POST_BATCH,
          true
        );
      }
    });

    // the schema filtered for only preSchedule fields
    const preScheduleWorkflowSchema = computed(() => {
      if (selectedTopLevelAction.value) {
        return OpsController.Instance.getActionSchema(
          selectedTopLevelAction.value.id,
          InteractivityHookStage.INTERACTIVITY_HOOK_PRE_SCHEDULE,
          true
        );
      }
    });

    // Job Details Module =======================================
    const jobName = ref('');
    const assignee = ref({ id: '', name: '' });
    const selectedJobIds: Ref<string[]> = ref(
      props.job?.id ? [props.job?.id] : []
    );
    const jobToSchedule: Ref<string> = ref(props.job?.id || '');
    const parameterValues: Ref<SimpleResponse> = ref({});
    let requestModuleData: SimpleResponse = {};
    let scheduleResponseData: SimpleResponse = {};
    let configResponseData: SimpleResponse = {};
    const validResponseData = ref(false);
    const validSchedulerData = ref(true);
    const validConfigData = ref(true);

    const assignees = UserController.Instance.users.map((u) => {
      return { name: u.name, id: u.id };
    });

    const handleDetailsUpdate = (payload) => {
      if (payload.updateType === 'name') {
        jobName.value = payload.data;
      } else if (payload.updateType === 'assignee') {
        assignee.value = payload.data;
      } else if (payload.updateType === 'selectedJobIds') {
        selectedJobIds.value = payload.data;
      }
    };

    // set init value
    jobName.value = props.job?.name || '';
    assignee.value = assignees.find(
      (a) => a.id === props.job?.common.createdBy
    ) || { id: '', name: '' };

    // Scheduler Module =======================================
    const date = ref(new Date());
    const time = ref(new Date());
    const strategy = ref('asap');
    const previewScheduleId = ref('');

    date.value = new Date();
    time.value = new Date();

    const jobAssistants = computed(() => {
      const actions = props.job ? props.job.action : selectedWorkflow.value;

      if (selectedWorkflow.value?.actionType === ActionType.WORKFLOW) {
        return actions?.children?.filter(
          (a) => a.actionType === ActionType.ASSISTANT
        );
      }
      return [actions];
    });

    const handleSchedulerUpdates = (payload) => {
      if (payload.updateType === 'date') {
        date.value = payload.data;
      } else if (payload.updateType === 'time') {
        time.value = payload.data;
      } else if (payload.updateType === 'strategy') {
        strategy.value = payload.data;
      } else if (payload.updateType === 'responseData') {
        scheduleResponseData = payload.data;
      } else if (payload.updateType === 'responseError') {
        validSchedulerData.value = payload.data ? payload.data.valid : true;
      } else if (payload.updateType === 'scheduleId') {
        previewScheduleId.value = payload.data;
      }
    };

    /**
     * Job configuration
     */
    const noConfig = ref(false);
    if (props.job) {
      ConfigController.Instance.dispatchGetJobConfig(props.job.id)
        .then((config) => {
          noConfig.value = config.schemaVersion === 0;
        })
        .catch(() => {
          noConfig.value = true;
        });
    }

    const handleJobConfigDataUpdate = (payload) => {
      if (payload.updateType === 'responseData') {
        configResponseData = payload.data;
      } else if (payload.updateType === 'responseError') {
        validConfigData.value = payload.data.valid;
      }
    };

    const submitJobConfigData = async () => {
      if (!isEmpty(configResponseData)) {
        await ConfigController.Instance.dispatchUpdateJobConfig(
          jobToSchedule.value,
          configResponseData
        );
      }
      return [];
    };

    const handleBatchJob = async (): Promise<string[]> => {
      try {
        let validation = await OpsController.Instance.dispatchValidateJobs(
          selectedJobIds.value,
          InteractivityHookStage.INTERACTIVITY_HOOK_PRE_BATCH
        );
        if (validation.success) {
          if (selectedJobIds.value.length > 1) {
            const batchedJob = await OpsController.Instance.dispatchBatchJobs(
              selectedJobIds.value
            );
            jobToSchedule.value = batchedJob.id;
            selectedJobIds.value = [batchedJob.id];
            selectedTopLevelAction.value = batchedJob?.action || null;
            try {
              parameterValues.value = JSON.parse(
                batchedJob.action?.parameterValues || ''
              );
            } catch (_) {
              console.error(
                `Invalid parameter values from batched job: ${batchedJob.id}`
              );
            }
          } else if (selectedJobIds.value.length === 1) {
            jobToSchedule.value = selectedJobIds.value[0];
            const job = OpsController.Instance.getJob(jobToSchedule.value);
            selectedTopLevelAction.value = job?.action || null;
            parameterValues.value =
              OpsController.Instance.getActionParameterValues(
                job.action?.id || ''
              ) || {};
          }
          const newJob = OpsController.Instance.getJob(jobToSchedule.value);
          validation = await OpsController.Instance.dispatchValidateJobs(
            [jobToSchedule.value],
            InteractivityHookStage.INTERACTIVITY_HOOK_POST_BATCH
          );
          if (validation.success) {
            await OpsController.Instance.dispatchRefetchSchema(
              newJob.action?.id || ''
            );
            return [];
          }
        }
        return validation.errors;
      } catch (error) {
        return [error as string];
      }
    };

    const handleUnbatchJob = async () => {
      if (OpsController.Instance.getJob(jobToSchedule.value).children?.length) {
        await OpsController.Instance.dispatchUnbatchJob(jobToSchedule.value);
        jobToSchedule.value = '';
      }
      selectedJobIds.value = [];
    };

    const back = () => {
      UIController.Instance.displayLeftDrawer = false;
      handleUnbatchJob();
      emit('close');
    };

    const postBatchDataRequired = computed(() => {
      if (workflowSchema.value) {
        return some(
          workflowSchema.value.properties as RequestJSONSchema,
          // @ts-ignore
          (p) => p['ui:options']?.['hooks:postBatch'] || p['ui:hooks:postBatch']
        );
      }
      return false;
    });

    const handleJobModuleDataUpdate = ({
      responseData,
      validation,
    }: {
      responseData: SimpleResponse;
      validation: OutputUnit;
    }) => {
      if (responseData) {
        requestModuleData = responseData;
      }
      if (validation) {
        validResponseData.value = validation.valid;
      }
    };

    const submitJobModuleData = async () => {
      const job = OpsController.Instance.getJob(jobToSchedule.value);
      if (job.action?.id) {
        await OpsController.Instance.dispatchSetActionParameterValues(
          job.action.id,
          requestModuleData
        );
        parameterValues.value = requestModuleData;
      }
    };

    const validateJobModuleData = async (
      hook: InteractivityHookStage
    ): Promise<JobValidationResponse> => {
      const job = OpsController.Instance.getJob(jobToSchedule.value);
      if (job.id) {
        const response = await OpsController.Instance.dispatchValidateJobs(
          [job.id],
          hook
        );
        return response;
      }
      return {
        success: false,
        errors: ['Cannot validate input data: Invalid Job'],
      };
    };

    const submitAndValidateJobModuleData = (
      hook: InteractivityHookStage
    ): (() => Promise<string[]>) => {
      return async (): Promise<string[]> => {
        await submitJobModuleData();
        const response = await validateJobModuleData(hook);
        if (response.success) {
          if (response.errors) {
            console.warn(
              'Job validation: success with warnings',
              response.errors
            );
          }
          return [];
        }
        return response.errors;
      };
    };

    const submitSchedulingData = async (): Promise<string[]> => {
      if (!isEmpty(scheduleResponseData)) {
        const job = OpsController.Instance.getJob(jobToSchedule.value);
        if (job.action?.id) {
          await OpsController.Instance.dispatchSetActionParameterValues(
            job.action.id,
            scheduleResponseData
          );
        }
      }
      return [];
    };

    // @ts-ignore
    const scheduleSteps: ComputedRef<Step[]> = computed(() =>
      [
        {
          id: 'typeSelection',
          name: 'Request Type',
          props: {
            labId: props.labId,
            forJob: true,
            isWorkflow: true,
            selectedAction: selectedWorkflow.value,
          },
          component: WorkflowRequest,
          displayCondition: !props.job,
          disabledCondition: false,
          action: (workflow) => {
            selectedWorkflow.value = workflow;
            if (selectedWorkflow.value?.id) {
              // to know if we have to display config, we need to check the WORKFLOW'S
              // config because we haven't necessarily selected a job yet.
              ConfigController.Instance.dispatchGetWorkflowConfig(
                selectedWorkflow.value.id
              )
                .then((config) => {
                  noConfig.value = config.schemaVersion === 0;
                })
                .catch(() => {
                  noConfig.value = true;
                });
            }
          },
          hideNext: true,
          setStepActive: handleUnbatchJob,
          fullHeight: true,
        },
        {
          id: 'batching',
          name: 'Define Job Details',
          props: {
            job: props.job,
            jobName: jobName.value,
            assignee: assignee.value,
            assignees,
            selectedJobIds: selectedJobIds.value,
            labId: props.labId,
            workflowId: selectedWorkflow.value?.id,
          },
          component: JobDetailsModule,
          displayCondition: true,
          disabledCondition: computed(
            () =>
              jobName.value.length === 0 && selectedJobIds.value.length === 0
          ).value,
          action: handleDetailsUpdate,
          stepForward: handleBatchJob,
          setStepActive: handleUnbatchJob,
        },
        {
          id: 'jobModule',
          name: 'Check and modify job parameters',
          props: {
            schema: postBacthWorkflowSchema.value,
            data: parameterValues.value,
            hook: InteractivityHookStage.INTERACTIVITY_HOOK_POST_BATCH,
          },
          component: ParameterValueInfo,
          displayCondition: computed(() => postBatchDataRequired.value).value,
          disabledCondition: computed(() => !validResponseData.value).value,
          action: handleJobModuleDataUpdate,
          stepForward: submitAndValidateJobModuleData(
            InteractivityHookStage.INTERACTIVITY_HOOK_POST_BATCH
          ),
        },
        {
          id: 'jobConfig',
          name: 'Check and modify job configuration',
          props: {
            jobId: jobToSchedule.value,
          },
          component: JobConfig,
          displayCondition: computed(() => !noConfig.value).value,
          disabledCondition: computed(() => !validConfigData.value).value,
          action: handleJobConfigDataUpdate,
          stepForward: submitJobConfigData,
        },
        {
          id: 'schedule',
          name: 'Schedule',
          props: {
            labId: props.labId,
            jobId: selectedJobIds.value[0] || null,
            date: date.value,
            time: time.value,
            assistants: jobAssistants.value?.filter((j) => j?.name),
            workflowSchema: preScheduleWorkflowSchema.value,
          },
          component: SchedulerModule,
          displayCondition: true,
          disabledCondition: computed(() => !validSchedulerData.value).value,
          action: handleSchedulerUpdates,
          stepForward: submitSchedulingData,
        },
        {
          id: 'reviewAndSubmit',
          name: 'Review & Submit',
          props: {
            jobId: jobToSchedule.value,
            strategy: strategy.value,
            scheduledDate: date.value,
            scheduledTime: time.value,
          },
          component: ReviewAndSubmit,
          displayCondition: true,
          disabledCondition: false,
        },
      ].filter((s) => s.displayCondition)
    );

    const schedulerError = ref('');

    const handleCompletion = async () => {
      const schedTime = new Date(time.value);
      const schedDate = moment(date.value);
      schedDate.set('hours', schedTime.getHours());
      schedDate.set('minutes', schedTime.getMinutes());

      const scheduleId = await OpsController.Instance.dispatchScheduleJob(
        jobToSchedule.value,
        [jobToSchedule.value],
        'time',
        (schedDate.valueOf() / 1000).toString(),
        previewScheduleId.value
      );

      const newJob = OpsController.Instance.getJob(jobToSchedule.value);

      if (scheduleId) {
        jobToSchedule.value = '';
        selectedWorkflow.value = null;
        selectedTopLevelAction.value = null;
        parameterValues.value = {};
        emit('close', newJob);
        UIController.Instance.displayLeftDrawer = false;
      }
    };

    const cancelAction = () => {
      back();
    };

    watch(
      () => props.job,
      (newJob) => {
        if (newJob?.action) {
          selectedTopLevelAction.value = newJob.action;
          selectedWorkflow.value = ActionController.Instance.getAction(
            newJob.workflowId
          );
          parameterValues.value =
            OpsController.Instance.getActionParameterValues(newJob.action.id) ||
            {};
        }
      },
      { immediate: true }
    );

    // close left drawer
    onUnmounted(() => {
      if (jobToSchedule.value) {
        // we didn't finish scheduling the job, unbatch it if necessary
        handleUnbatchJob();
      }
      UIController.Instance.displayLeftDrawer = false;
    });

    return {
      back,
      selectedWorkflow,
      selectedJobIds,
      scheduleSteps,
      handleCompletion,
      cancelAction,
      schedulerError,
    };
  },
});
