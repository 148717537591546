
import { defineComponent, reactive, ref, Ref, PropType } from 'vue';
import { Lab } from '@/clients/ops';
import { Action } from '@/clients/action';

export interface ModalPayloadInterface {
  name: string;
  location: string;
  description: string;
  version: string;
  label?: string;
  labId?: string;
  actionId?: string;
  numSamples?: number;
  compliant?: boolean;
}

// interface Props {
//   title: string;
//   open: boolean;
//   includeLabel: boolean;
//   includeLocation: boolean;
//   includeSamples: boolean;
//   labRefs: Lab[];
//   actions: Action[];
//   hideNew: boolean;
//   isMultiselect: boolean;
// }

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    includeLabel: Boolean,
    includeLocation: Boolean,
    includeSamples: Boolean,
    labRefs: {
      type: Array as PropType<Lab[]>,
      required: false,
    },
    actions: {
      type: Array as PropType<Action[]>,
      required: false,
    },
    hideNew: Boolean,
    isMultiselect: Boolean,
  },
  setup(props, { emit }) {
    // Make linter error go away
    const state: ModalPayloadInterface = reactive({
      name: '',
      location: '',
      description: '',
      label: '',
      labId: '',
      actionId: '',
      numSamples: 0,
      version: '1.0.0',
    });
    const fileBrowser: Ref<HTMLInputElement | null> = ref(null);

    function save() {
      emit('save', {
        name: state.name,
        location: state.location,
        description: state.description,
        version: state.version,
        label: state.label,
        labId: state.labId,
        actionId: state.actionId,
        numSamples: state.numSamples,
      } as ModalPayloadInterface);
      close();
    }

    function importEntity(json) {
      emit('import', json);
      close();
    }

    function clickImport() {
      if (fileBrowser.value) {
        fileBrowser.value.click();
      }
    }

    function processFile(e) {
      importEntity(e.target.files);
    }

    function clear() {
      state.name = '';
      state.location = '';
      state.description = '';
    }

    function close() {
      emit('close');
    }

    return {
      fileBrowser,
      processFile,
      activeTab: ref(props.hideNew ? 'import' : 'new'),
      save,
      clickImport,
      clear,
      close,
      state,
    };
  },
});
