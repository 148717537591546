import { isFunction } from 'lodash';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import AuthController from '@/clients/auth';
import TokenController from '@/clients/tokens';
import UIController from '@/clients/ui';
import LabOpsController from '@/pages/Ops/planningLocalController';
import Loading from '@/pages/Loading/index.vue';
import LabOps from '@/pages/Home/LabOps.vue';
import LabEditor from '@/pages/LabEditor/index.vue';
import Ops from '@/pages/Ops/Ops.vue';
import Results from '@/pages/Results/index.vue';
import Labs from '@/pages/Files/Labs.vue';
import Assistants from '@/pages/Files/Assistants.vue';
import Workflows from '@/pages/Files/Workflows.vue';
import WorkflowDetails from '@/pages/Workflow/WorkflowDetails.vue';
import DataRecords from '@/pages/DataRecords/DataRecords.vue';
import RequestParams from '@/pages/DataRecords/RequestParamsDetails.vue';
import JobDetails from '@/pages/JobDetails/index.vue';
import Demo from '@/pages/Demo/index.vue';
import Tokens from '@/pages/Tokens/index.vue';
import Settings from '@/pages/Settings/Settings.vue';
import NoAccess from '@/pages/Settings/NoAccess.vue';
import Diag from '@/pages/Settings/Diag.vue';
import James from '@/pages/James/index.vue';
import CloseWindow from '@/components/CloseWindow.vue';
import * as NAMES from './names';
import { AUTH_URL, COGNITO_CLIENT_ID } from '@/clients/utils';
import { UserRoles } from '@/clients/users/model';
import { nextTick } from 'vue';

const homeRoute = {
  path: '/',
  name: NAMES.HOME_ROUTE,
  component: LabOps,
  props: (route) => ({
    selectedTab: route.query.t,
  }),
  meta: {
    title: 'LabOps',
    requiresAuth: true,
    icon: '/favicon-artificial.svg',
  },
};

const ops: RouteRecordRaw = {
  path: '/ops/:labId/:jobId?',
  name: NAMES.OPS,
  component: Ops,
  meta: {
    title: 'LabOps',
    requiresAuth: true,
    icon: '/favicon-artificial.svg',
  },
};

const routes: RouteRecordRaw[] = [
  {
    path: '/labs',
    component: Labs,
    name: NAMES.LABS_ROUTE,
    meta: {
      title: 'Labs',
      requiresAuth: true,
      icon: 'favicon-artificial.svg',
    },
  },
  {
    path: '/assistants',
    component: Assistants,
    name: NAMES.ASSISTANTS_ROUTE,
    meta: {
      title: 'Assistants',
      requiresAuth: true,
      icon: 'favicon-artificial.svg',
    },
  },
  {
    path: '/workflows',
    component: Workflows,
    name: NAMES.WORKFLOWS_ROUTE,
    meta: {
      title: 'Workflows',
      requiresAuth: true,
      icon: 'favicon-artificial.svg',
    },
  },
  {
    path: '/results',
    component: Results,
    name: NAMES.RESULTS_ROUTE,
    meta: {
      title: 'Results',
      requiresAuth: true,
      icon: 'favicon-artificial.svg',
    },
  },
  {
    path: '/data-records/:jobId',
    component: DataRecords,
    name: NAMES.DATA_RECORDS_ROUTE,
    meta: {
      title: 'Data Records',
      requiresAuth: true,
      icon: 'favicon-artificial.svg',
    },
  },
  {
    path: '/workflow/:workflowId',
    name: NAMES.WORKFLOW_DETAILS,
    component: WorkflowDetails,
    meta: {
      requiresAuth: true,
      title: 'Workflow details',
      canGoBack: true,
      icon: '/favicon-workflows.svg',
    },
  },
  {
    path: '/request-params/:jobId',
    component: RequestParams,
    name: NAMES.REQUEST_PARAMS_ROUTE,
    meta: {
      title: 'Request Parameters',
      requiresAuth: true,
      icon: 'favicon-artificial.svg',
    },
  },
  {
    path: '/loading',
    component: Loading,
    name: NAMES.LOADING_ROUTE,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/settings',
    name: NAMES.SETTINGS,
    component: Settings,
    meta: {
      requiresAuth: true,
      title: 'Settings',
      canGoBack: true,
      icon: '/favicon-artificial.svg',
    },
  },
  {
    path: '/james',
    name: 'james',
    component: James,
    meta: {
      requiresAuth: true,
      title: 'James',
      canGoBack: true,
      icon: '/favicon-artificial.svg',
    },
  },
  {
    path: '/',
    name: NAMES.LAB_OPS,
    component: LabOps,
    children: [
      // @ts-ignore
      {
        path: '/sign-in',
        name: NAMES.ENTRY_SIGN_IN_ROUTE,
      },
      // @ts-ignore
      {
        path: '/sign-out',
        name: NAMES.ENTRY_SIGN_OUT_ROUTE,
      },
    ],
    meta: {
      title: 'LabOps',
      requiresAuth: true,
      icon: '/favicon-artificial.svg',
    },
  },
  homeRoute,
  ops,
  {
    path: '/lab-editor',
    name: NAMES.LAB_EDITOR_ROUTE,
    component: LabEditor,
    props: (route) => {
      return {
        labId: route.query.labId,
        assistantId: route.query.assistantId,
        workflow: route.query.workflow,
        edit: false,
      };
    },
    meta: {
      requiresAuth: true,
      title: '',
      canGoBack: true,
      icon: (route): string => {
        const iconString = route?.query?.assistantId
          ? '/favicon-assistant.svg'
          : '/favicon-labs.svg';
        return iconString;
      },
    },
  },
  {
    path: '/lab',
    name: NAMES.LAB_ROUTE,
    component: LabEditor,
    props: (route) => {
      return {
        labId: route.query.labId,
        assistantId: route.query.assistantId,
        edit: false,
      };
    },
    meta: {
      requiresAuth: true,
      title: '',
      canGoBack: true,
      icon: (route) =>
        route.query.assistantId
          ? '/favicon-assistant.svg'
          : '/favicon-labs.svg',
    },
  },
  // {
  //   path: '/protocol-editor/:protocolId',
  //   name: NAMES.PROTOCOL_EDITOR_ROUTE,
  //   component: ProtocolEditor,
  //   props: true,
  //   meta: {
  //     requiresAuth: true,
  //     title: 'Protocol editor',
  //     canGoBack: true,
  //     icon: '/favicon-protocols.png',
  //     breadcrumbIcon: () => protocolIcon,
  //     breadcrumbName: (route: Route) =>
  //       ProtocolController.Instance.getProtocol(route.params.protocolId).name,
  //   },
  // },
  // {
  //   path: '/workflow-mapper/:workflowId',
  //   name: NAMES.WORKFLOW_MAPPER_ROUTE,
  //   component: WorkflowMapper,
  //   props: true,
  //   meta: {
  //     requiresAuth: true,
  //     title: 'Workflow mapper',
  //     canGoBack: true,
  //     icon: '/favicon-workflows.png',
  //     breadcrumbIcon: () => workflowIcon,
  //     breadcrumbName: (route: Route) =>
  //       WorkflowController.Instance.getWorkflow(route.params.workflowId).name,
  //   },
  // },
  {
    path: '/job-details/:jobId',
    name: NAMES.JOB_DETAILS_ROUTE,
    component: JobDetails,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Job Details',
      canGoBack: true,
      icon: '/favicon-artificial.svg',
    },
  },
  {
    path: '/plan',
    name: 'plan',
    component: Demo,
    meta: {
      requiresAuth: true,
      title: 'Semantic Demo',
      canGoBack: true,
      icon: '/favicon-protocols.svg',
    },
  },
  {
    path: '/tokens',
    name: NAMES.TOKENS_ROUTE,
    component: Tokens,
    meta: {
      requiresAuth: true,
      title: 'Tokens',
      canGoBack: true,
      icon: '/favicon-artificial.svg',
    },
  },
  {
    path: '/noaccess',
    name: NAMES.NO_ACCESS,
    component: NoAccess,
    meta: {
      requiresAuth: true,
      title: 'No Access',
      icon: '/favicon-artificial.svg',
    },
  },
  {
    path: '/diag',
    name: NAMES.DIAG,
    component: Diag,
    meta: {
      requiresAuth: false,
      title: 'Diagnostics',
      icon: '/favicon-artificial.svg',
    },
  },
  {
    path: '/vscode-login',
    component: CloseWindow,
    beforeEnter(to) {
      const instanceURL = to.query.instanceURL;
      const redirect = to.query.redirect;
      if (
        instanceURL &&
        redirect &&
        !Array.isArray(instanceURL) &&
        !Array.isArray(redirect)
      ) {
        TokenController.Instance.dispatchNewToken('vscode')
          .then((hash) => {
            window.location.href = `${decodeURI(
              redirect
            )}?token=${hash}&instanceURL=${decodeURI(instanceURL)}`;
          })
          .catch((e) => {
            console.error('Error creating token', e);
          });
      } else {
        console.error('Invalid query parameters');
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const requestedRoute = {
  name: homeRoute.name,
  params: {},
  meta: homeRoute.meta,
  query: {},
};

router.beforeEach((to) => {
  if (to.name === NAMES.DIAG || to.name === NAMES.NO_ACCESS) {
    return;
  }
  if (to.name !== NAMES.OPS) {
    UIController.Instance.displayBottomDrawer = false;
    LabOpsController.Instance.requestState = null;
    UIController.Instance.displayLeftDrawer = false;
  }
  if (!AuthController.Instance.known) {
    // If loggedIn is not known, save the requested route and load a spinner
    if (to.name === NAMES.LOADING_ROUTE) {
      return;
    } else {
      requestedRoute.name = to.name?.toString() || '';
      requestedRoute.params = to.params;
      // @ts-ignore
      requestedRoute.meta = to.meta;
      requestedRoute.query = to.query;
      return { name: NAMES.LOADING_ROUTE };
    }
  } else {
    console.log(requestedRoute);
    if (requestedRoute.meta && requestedRoute.meta.requiresAuth) {
      console.log('in requires auth');
      if (AuthController.Instance.loggedIn) {
        const isValidUser = !!AuthController.Instance.user.roleIds.find(
          (r) => r === UserRoles.MEMBER || r === UserRoles.ADMIN
        );
        if (!isValidUser) {
          return { name: NAMES.NO_ACCESS, replace: true };
        } else if (isValidUser && requestedRoute.name !== NAMES.NO_ACCESS) {
          return;
        } else {
          return { name: NAMES.HOME_ROUTE };
        }
      } else if (to.name === NAMES.ENTRY_SIGN_OUT_ROUTE) {
        location.href = COGNITO_CLIENT_ID
          ? `${AUTH_URL}/logout?client_id=${COGNITO_CLIENT_ID}&redirect_uri=${encodeURIComponent(
              location.href
            )}`
          : `${AUTH_URL}/logout?redirect_uri=${encodeURIComponent(
              location.href
            )}`;
      } else {
        const newLoc = COGNITO_CLIENT_ID
          ? `${AUTH_URL}/login?client_id=${COGNITO_CLIENT_ID}&redirect_uri=${encodeURIComponent(
              location.href
            )}`
          : `${AUTH_URL}/login?redirect_uri=${encodeURIComponent(
              location.href
            )}`;
        location.href = newLoc;
      }
    } else if (to.name === NAMES.LOADING_ROUTE) {
      // Handle special routes which should only be used when loggedIn is unknown.
      return { name: NAMES.HOME_ROUTE };
    } else {
      return { ...requestedRoute };
    }
  }
});

const getIcon = (to): string | undefined => {
  const useTecanBranding =
    UIController.Instance.hasFeatureFlag('USE_TECAN_BRANDING');

  if (useTecanBranding) return 'favicon-tecan.svg';
  if (!to.meta.icon) return;
  return isFunction(to.meta.icon)
    ? // @ts-ignore
      to.meta.icon(to)
    : // @ts-ignore
      to.meta.icon;
};

// See https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
router.afterEach((to) => {
  UIController.Instance.pushBreadcrumbRoute(to);

  nextTick(() => {
    const newIcon = getIcon(to);
    if (newIcon) {
      const link = document.querySelector('[rel="icon"]') as HTMLLinkElement;
      if (link) {
        const faviconUrl = new URL(newIcon, window.location.origin).toString();
        console.log('setting favicon from', link.href, 'to', faviconUrl);
        link.setAttribute('href', faviconUrl);
      }
    }

    // change titles
    // Note:  This may be further changed by specific pages.
    // @ts-ignore
    if (to.meta.title) document.title = to.meta.title || companyName;
  });
});
export { NAMES };
export default router;
