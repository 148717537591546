import { gql } from '@apollo/client/core';

const COMMON_FIELDS = gql`
  fragment CommonFields on Common {
    createdBy
    displayOwnerName
    createdTimestamp
    modifiedTimestamp
    revision
  }
`;

const TIMELOG_FIELDS = gql`
  fragment TimelogFields on Timelog {
    estimate {
      startTimestamp
      endTimestamp
    }
    actual {
      startTimestamp
      endTimestamp
    }
  }
`;

export const DEPLOYMENT_CONFIG_FIELDS = gql`
  fragment DeploymentConfigFields on Lab {
    deploymentConfiguration
  }
`;

export const CORE_LAB_FIELDS = gql`
  fragment CoreLabFields on Lab {
    id
    name
    description
    state
    location
    label
    version
    thumbnailUrl
    adapterIds
    connections {
      client {
        name
        instance
        version
        healthInfo {
          key
          value
        }
      }
      server {
        name
        instance
        version
      }
      scope
    }
    bannedConnections
    common {
      ...CommonFields
    }
  }
  ${COMMON_FIELDS}
`;

const CORE_JOB_FIELDS = gql`
  fragment CoreJobFields on Job {
    id
    name
    state
    labId
    parentId
    workflowId
    action {
      id
      actionType
    }
    numSamples
    children {
      id
    }
    schedule {
      tactic
    }
    common {
      ...CommonFields
    }
    timelog {
      estimate {
        startTimestamp
        endTimestamp
      }
      actual {
        startTimestamp
        endTimestamp
      }
    }
  }
  ${COMMON_FIELDS}
`;

const RESULTS_JOB_FIELDS = gql`
  fragment ResultsJobFields on Job {
    name
    id
    state
    labId
    numSamples
    workflowId
    action {
      name
    }
    inputs {
      type
    }
    outputs {
      type
    }
    timelog {
      actual {
        startTimestamp
        endTimestamp
      }
    }
  }
`;

const ALL_IO_FIELDS = gql`
  fragment AllIoFields on Io {
    id
    name
    type
    metadatas {
      blob
    }
    actualquantity {
      unit
      value
    }
    samples {
      id
      name
      description
      metadata
      common {
        createdBy
      }
    }
    assets {
      id
      name
      common {
        createdBy
      }
    }
    userFields {
      key
      value
    }
  }
`;

const CORE_ACTION_FIELDS = gql`
  fragment CoreActionFields on Action {
    id
    name
    state
    actionType
    jobId
    parentId
    actorId
    definedFrom {
      id
      rev
    }
    common {
      ...CommonFields
    }
    constraint {
      labId
    }
  }
  ${COMMON_FIELDS}
`;

const JOB_CARD_FIELDS = gql`
  fragment JobCardFields on Job {
    ...CoreJobFields
    timelog {
      ...TimelogFields
    }
    children {
      id
      name
      state
      timelog {
        ...TimelogFields
      }
    }
    action {
      name
      actionType
    }
  }
  ${CORE_JOB_FIELDS}
  ${TIMELOG_FIELDS}
  ${COMMON_FIELDS}
`;

const JOB_DETAILS_FIELDS = gql`
  fragment JobDetailsFields on Job {
    ...CoreJobFields
    spawnedBy
    spawnedJobs
    inputs {
      ...AllIoFields
    }
    outputs {
      ...AllIoFields
    }
    timelog {
      ...TimelogFields
    }
    children {
      id
      name
      numSamples
      parentId
      common {
        ...CommonFields
      }
    }
    notes {
      text
      userId
      createdTimestamp
    }
    action {
      ...CoreActionFields
      completedBy
      timelog {
        ...TimelogFields
      }
    }
  }
  ${CORE_JOB_FIELDS}
  ${ALL_IO_FIELDS}
  ${TIMELOG_FIELDS}
  ${CORE_ACTION_FIELDS}
  ${COMMON_FIELDS}
`;

export const LEGACY_JOB_DETAILS_FIELDS = gql`
  fragment LegacyJobDetailsFields on Job {
    ...CoreJobFields
    spawnedBy
    spawnedJobs
    inputs {
      ...AllIoFields
    }
    outputs {
      ...AllIoFields
    }
    timelog {
      ...TimelogFields
    }
    children {
      id
      name
      numSamples
      parentId
      common {
        ...CommonFields
      }
    }
    notes {
      text
      userId
      createdTimestamp
    }
    action {
      ...CoreActionFields
      schema
      parameterValues
      completedBy
      timelog {
        ...TimelogFields
      }
      children {
        ...CoreActionFields
        completedBy
        timelog {
          ...TimelogFields
        }
        children {
          ...CoreActionFields
          completedBy
          timelog {
            ...TimelogFields
          }
          assistant {
            hideStep
          }
        }
      }
    }
  }
  ${CORE_JOB_FIELDS}
  ${ALL_IO_FIELDS}
  ${TIMELOG_FIELDS}
  ${CORE_ACTION_FIELDS}
  ${COMMON_FIELDS}
`;

export const JOB_EVENT_FIELDS = gql`
  fragment JobEventFields on JobEvent {
    eventType
    redisTimestamp
    labId
    actionId
    actionType
    actionName
    initiatorId
    initiatorType
    senderId
    senderType
    details
    inputParameters
    outputParameters
  }
`;

export const THIN_JOB_DETAILS_W_IO_FIELDS = gql`
  fragment ThinJobDetailsFieldsWithIo on Job {
    ...CoreJobFields
    inputs {
      ...AllIoFields
    }
    outputs {
      ...AllIoFields
    }
    timelog {
      ...TimelogFields
    }
    children {
      id
      name
      numSamples
      common {
        ...CommonFields
      }
    }
    action {
      name
      schema
      parameterValues
      actionType
    }
  }
  ${CORE_JOB_FIELDS}
  ${ALL_IO_FIELDS}
  ${TIMELOG_FIELDS}
  ${COMMON_FIELDS}
`;

const EXPORT_JOBS_FIELDS = gql`
  fragment ExportJobsFields on Job {
    ...CoreJobFields
    numSamples
    inputs {
      ...AllIoFields
    }
    outputs {
      ...AllIoFields
    }
    timelog {
      ...TimelogFields
    }
    actions {
      ...CoreActionFields
      numSamples
      inputs {
        id
        type
      }
      outputs {
        id
        type
      }
      timelog {
        ...TimelogFields
      }
      completedBy
      children {
        id
      }
    }
    children {
      id
      name
      numSamples
      common {
        createdTimestamp
        modifiedTimestamp
      }
    }
  }
  ${CORE_JOB_FIELDS}
  ${ALL_IO_FIELDS}
  ${CORE_ACTION_FIELDS}
  ${TIMELOG_FIELDS}
`;

export const SCHEDULE_RESPONSE_FIELDS = gql`
  fragment ScheduleResponseFields on ScheduleResponse {
    scheduleId
    solutionInfo {
      solutionFound
    }
    errors {
      errorType {
        ... on ScheduleError_MissingActorAbility {
          __typename
          missingActorAbility {
            actorId
            abilityName
          }
        }
      }
    }
  }
`;

export const PUBLIC_ERROR_FIELDS = gql`
  fragment PublicErrorFields on PublicError {
    labId
    jobId
    actionId
    workflowId
    category
    errorId
    errorCode
    title
    errorText
    lineNumber
    severity
    timestamp
    sourceFile
    sourceModule
  }
`;

export const OPS_QUERY = gql`
  query GetLab($id: ID!) {
    lab(id: $id) {
      ...CoreLabFields
      numRunningJobs
      numRunningNeedAssistanceJobs
      numRunningWithAssistanceJobs
      numRunningWithErrorJobs
      numInitializedJobs
      numOnHoldJobs
      numPausedJobs
    }
  }
  ${CORE_LAB_FIELDS}
`;

export const OPS_TABLE_QUERY = gql`
  query OpsTableQuery(
    $offset: Int
    $limit: Int
    $filters: JobFilters
    $orderBy: [String]
  ) {
    jobs(offset: $offset, limit: $limit, filters: $filters, orderBy: $orderBy) {
      jobs {
        id
        name
        state
        labId
        workflowId
        spawnedBy
        spawnedJobs
        common {
          createdBy
          createdTimestamp
          modifiedTimestamp
          revision
        }
        parentId
        action {
          id
          name
          actionType
        }
        timelog {
          ...TimelogFields
        }
        notes {
          text
          userId
          createdTimestamp
        }
      }
      totalCount
      offset
      limit
    }
  }
  ${TIMELOG_FIELDS}
`;

export const AVAILABLE_REQUESTS_QUERY = gql`
  query AvailableRequestsQuery($filters: JobFilters, $orderBy: [String]) {
    jobs(offset: 0, noLimit: true, filters: $filters, orderBy: $orderBy) {
      jobs {
        id
        name
        labId
        workflowId
        parentId
        common {
          createdBy
          createdTimestamp
        }
        action {
          id
          name
          actionType
          schema
          parameterValues
        }
      }
      totalCount
    }
  }
`;

export const RESULTS_QUERY = gql`
  query ResultsQuery($filters: JobFilters, $orderBy: [String]) {
    jobs(offset: 0, noLimit: true, filters: $filters, orderBy: $orderBy) {
      jobs {
        id
        name
        labId
        workflowId
        state
        common {
          createdBy
          createdTimestamp
        }
        timelog {
          ...TimelogFields
        }
        action {
          id
          name
          actionType
          schema
          parameterValues
        }
      }
      totalCount
    }
  }
  ${TIMELOG_FIELDS}
`;

export const JOB_NAMES_QUERY = gql`
  query JobNamesQuery($filters: JobFilters, $orderBy: [String]) {
    jobs(offset: 0, noLimit: true, filters: $filters, orderBy: $orderBy) {
      jobs {
        id
        name
        labId
        common {
          createdBy
          createdTimestamp
        }
        timelog {
          ...TimelogFields
        }
      }
      totalCount
    }
  }
  ${TIMELOG_FIELDS}
`;

export const NEW_JOB_MUTATION = gql`
  mutation NewJob(
    $name: String!
    $labId: ID!
    $actionId: ID!
    $clientId: ID
    $actionsToCancel: [String]
    $file: String
    $parameterValues: String
  ) {
    newJob(
      name: $name
      labId: $labId
      actionId: $actionId
      clientId: $clientId
      actionsToCancel: $actionsToCancel
      file: $file
      parameterValues: $parameterValues
    ) {
      id
      name
      state
      workflowId
      common {
        createdTimestamp
        createdBy
      }
      action {
        id
        name
        actionType
      }
    }
  }
`;

export const OPS_SUBSCRIPTION_QUERY = gql`
  subscription SubscribeJobsInLab($labId: ID!, $clientId: ID!) {
    jobsInLab(labId: $labId, clientId: $clientId) {
      action
      full
      jobs {
        id
      }
    }
  }
`;

export const DASHBOARD_LABS_QUERY = gql`
  query GetLabs {
    labs {
      id
      name
      state
      common {
        ...CommonFields
      }
      numRunningJobs
      numRunningNeedAssistanceJobs
      numRunningWithErrorJobs
      numRunningWithAssistanceJobs
      numInitializedJobs
      numOnHoldJobs
      numPausedJobs
      thumbnailUrl
    }
  }
  ${COMMON_FIELDS}
`;

export const EXPORT_LABS_QUERY = gql`
  query GetLabs {
    labs {
      ...CoreLabFields
      jobs {
        ...ExportJobsFields
      }
    }
  }
  ${CORE_LAB_FIELDS}
  ${EXPORT_JOBS_FIELDS}
`;

export const SETTINGS_LAB_QUERY = gql`
  query GetLabs {
    labs {
      ...CoreLabFields
    }
  }
  ${CORE_LAB_FIELDS}
`;

export const SETTINGS_LAB_QUERY_WITH_DEPLOYMENT_CONFIG = gql`
  query GetLabs {
    labs {
      ...CoreLabFields
      ...DeploymentConfigFields
    }
  }
  ${CORE_LAB_FIELDS}
  ${DEPLOYMENT_CONFIG_FIELDS}
`;

export const LAB_QUERY = gql`
  query Lab($id: ID!) {
    lab(id: $id) {
      ...CoreLabFields
    }
  }
  ${CORE_LAB_FIELDS}
`;

export const LAB_QUERY_WITH_DEPLOYMENT_CONFIG = gql`
  query Lab($id: ID!) {
    lab(id: $id) {
      ...CoreLabFields
      ...DeploymentConfigFields
    }
  }
  ${CORE_LAB_FIELDS}
  ${DEPLOYMENT_CONFIG_FIELDS}
`;

export const RESULTS_LABS_QUERY = gql`
  query GetLabs {
    labs {
      id
      name
      state
      common {
        ...CommonFields
      }
    }
  }
  ${RESULTS_JOB_FIELDS}
  ${COMMON_FIELDS}
`;

export const JOB_CARD_QUERY = gql`
  query GetJobCardData($id: ID!) {
    job(id: $id) {
      ...JobCardFields
    }
  }
  ${JOB_CARD_FIELDS}
`;

export const JOB_DETAILS_QUERY = gql`
  query GetFullJob($id: ID!) {
    job(id: $id) {
      ...JobDetailsFields
    }
  }
  ${JOB_DETAILS_FIELDS}
`;

export const LEGACY_JOB_DETAILS_QUERY = gql`
  query GetFullJobLegacy($id: ID!) {
    job(id: $id) {
      ...LegacyJobDetailsFields
    }
  }
  ${LEGACY_JOB_DETAILS_FIELDS}
`;

export const THIN_JOB_DETAILS_W_IO_QUERY = gql`
  query GetThinJob($id: ID!) {
    job(id: $id) {
      ...ThinJobDetailsFieldsWithIo
    }
  }
  ${THIN_JOB_DETAILS_W_IO_FIELDS}
`;

export const SIMPLE_LAB_QUERY = gql`
  query GetLab($id: ID!) {
    lab(id: $id) {
      id
      name
    }
  }
`;

export const JOB_FILES_QUERY = gql`
  query GetFilesByJob($jobId: String!) {
    fileQuery(
      limit: 100
      offset: 0
      orderBy: ["asc", "id"]
      filters: { jobId: $jobId }
    ) {
      limit
      offset
      files {
        id
        contentType
        description
        fileSize
        jobId
        labId
        sourceTimestamp
        sourceFilename
        relativeDownloadPath
      }
    }
  }
`;
