import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-116cfdc5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-info__name" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = {
  key: 1,
  class: "user-info__letter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loggedIn)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "user-info",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToSettings && _ctx.goToSettings(...args)))
        }, [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.userName), 1),
          (_ctx.user.profileImage)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "user-info__image",
                src: _ctx.user.profileImage,
                alt: `${_ctx.user.name} profile image`
              }, null, 8, _hoisted_2))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.user.name[0].toUpperCase()), 1))
        ]))
      : _createCommentVNode("", true)
  ]))
}