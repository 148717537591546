
import { defineComponent, computed, ref, PropType } from 'vue';
import { Lab } from '@/clients/ops';
import { SimplifiedState } from '@/clients/model';
import defaultPreviewImage from '@/assets/lab-thumbnail-placeholder.png';

// interface Props {
//   previewImage: string;
//   icon: string;
//   resource: Lab;
//   detailsMode: boolean;
// }

export default defineComponent({
  props: {
    previewImage: String,
    icon: String,
    resource: {
      type: Object as PropType<Lab>,
      required: true,
    },
    detailsMode: Boolean,
  },
  setup(props, { emit }) {
    const labStateDisplayClass = ref('');

    const aggregateRunningJobs = computed(() => {
      return (
        (props.resource.numRunningJobs || 0) +
        (props.resource.numRunningNeedAssistanceJobs || 0) +
        (props.resource.numRunningWithAssistanceJobs || 0) +
        (props.resource.numRunningWithErrorJobs || 0)
      );
    });

    const labImage = props.resource.thumbnailUrl || defaultPreviewImage;

    const labStateDisplayString = computed((): string => {
      const labState = props.resource.state;
      switch (labState) {
        case SimplifiedState.ERROR: {
          labStateDisplayClass.value = 'error';
          return 'ERROR';
        }
        case SimplifiedState.PAUSED: {
          labStateDisplayClass.value = 'paused';
          return 'PAUSED';
        }
        case SimplifiedState.RUNNING_NEED_ASSISTANCE: {
          labStateDisplayClass.value = 'assistance';
          return 'ASSISTANCE NEEDED';
        }
        case SimplifiedState.RUNNING:
        case SimplifiedState.RUNNING_WITH_ASSISTANCE:
        case SimplifiedState.PAUSING:
        case SimplifiedState.CANCELLING:
        case SimplifiedState.RESUMING:
        case SimplifiedState.STOPPING: {
          labStateDisplayClass.value = 'running';
          return 'RUNNING';
        }
        case SimplifiedState.UNKNOWN:
        case SimplifiedState.CREATED:
        case SimplifiedState.INITIALIZED:
        case SimplifiedState.CANCELLED:
        case SimplifiedState.FINISHED:
        default: {
          labStateDisplayClass.value = 'ready';
          return 'READY FOR WORK';
        }
      }
    });

    const selectLab = (id: string) => {
      emit('select', id);
    };

    return {
      labImage,
      aggregateRunningJobs,
      labStateDisplayString,
      labStateDisplayClass,
      selectLab,
    };
  },
});
