import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e84c69c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "available-requests" }
const _hoisted_2 = { class: "requests-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_abbreviated_params = _resolveComponent("abbreviated-params")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, [
        _createTextVNode(" Available Requests "),
        _createVNode(_component_el_button, {
          icon: "RefreshLeft",
          circle: "",
          text: "",
          size: "large",
          onClick: _ctx.refreshPendingJobs,
          title: "Fetch new requests"
        }, null, 8, ["onClick"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_el_input, {
          modelValue: _ctx.searchString,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchString) = $event)),
          "prefix-icon": "Search"
        }, null, 8, ["modelValue"])
      ])
    ]),
    (_openBlock(), _createElementBlock("div", {
      class: "table-wrapper",
      ref: "tableWrapper",
      key: _ctx.tableHeight
    }, [
      _createVNode(_component_el_table, {
        data: _ctx.filteredJobs,
        "default-sort": { prop: 'createdDate', order: 'descending' },
        style: {"width":"100%","background":"transparent"},
        height: _ctx.tableHeight,
        "header-row-class-name": "request__header",
        "header-cell-class-name": "request__header",
        "row-class-name": "request__row",
        "cell-class-name": "request__cell",
        stripe: "",
        "row-style": 
          () => {
            return { background: 'transparent' };
          }
        
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, { width: "40" }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_checkbox, {
                modelValue: scope.row.selected,
                "onUpdate:modelValue": ($event: any) => ((scope.row.selected) = $event),
                onChange: ($event: any) => (_ctx.updateSelected(scope.row.id))
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "Request ID",
            property: "name",
            width: "200",
            sortable: ""
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "Date Created",
            property: "createdDate",
            width: "220",
            sortable: ""
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("span", null, _toDisplayString(_ctx.formatDateTime(scope.row.createdDate).datetime), 1)
            ]),
            _: 1
          }),
          (_ctx.showSampleCount)
            ? (_openBlock(), _createBlock(_component_el_table_column, {
                key: 0,
                label: "# of samples",
                sortable: ""
              }, {
                default: _withCtx((scope) => [
                  _createElementVNode("span", null, _toDisplayString(scope.row.numSamples || 'unknown'), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_table_column, { label: "Parameters" }, {
            default: _withCtx((scope) => [
              _createVNode(_component_abbreviated_params, {
                schema: _ctx.workflowSchema,
                params: _ctx.getJobParameters(scope.row),
                "job-id": scope.row.id
              }, null, 8, ["schema", "params", "job-id"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "Created by",
            property: "createdBy",
            width: "200",
            sortable: ""
          })
        ]),
        _: 1
      }, 8, ["data", "height", "row-style"])
    ]))
  ]))
}