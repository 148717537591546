import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45a64c14"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "leftbar-container"
}
const _hoisted_2 = { class: "center" }
const _hoisted_3 = {
  id: "app-body",
  ref: "appBody"
}
const _hoisted_4 = {
  key: 0,
  class: "lab-centric-leftnav-container"
}
const _hoisted_5 = {
  id: "center-portal-wrapper",
  ref: "centerPortal",
  class: "center-portal open"
}
const _hoisted_6 = {
  id: "center-portal",
  style: {"height":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Leftbar = _resolveComponent("Leftbar")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_LabCentricLeftNav = _resolveComponent("LabCentricLeftNav")!
  const _component_drawer_left = _resolveComponent("drawer-left")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_bottom_drawer = _resolveComponent("bottom-drawer")!
  const _component_drawer_right = _resolveComponent("drawer-right")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", {
    id: "app-main",
    class: _normalizeClass({ showRedLine: _ctx.showRedLine })
  }, [
    (_ctx.isLeftbarVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Leftbar)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([
          'navbar-container',
          {
            hide: _ctx.isNavbarHidden,
          },
        ])
      }, [
        _createVNode(_component_Navbar, {
          "show-right-artificial-icon": !_ctx.isLeftbarVisible
        }, null, 8, ["show-right-artificial-icon"])
      ], 2),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
        (_ctx.isNewLeftNavVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_LabCentricLeftNav)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          id: "left-drawer-wrapper",
          ref: "leftDrawer",
          class: "left-drawer",
          style: _normalizeStyle({ width: _ctx.leftWidth + 'px' })
        }, [
          _createVNode(_component_drawer_left, { onResizeDrawer: _ctx.handleLeftDrawerResize }, null, 8, ["onResizeDrawer"])
        ], 4),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_router_view)
          ]),
          _createElementVNode("div", {
            class: "bottom-drawer-wrapper",
            style: _normalizeStyle({
              height: _ctx.bottomHeight - 1 + 'px',
              top: _ctx.bottomTop - 50 + 'px',
              display: _ctx.bottomDrawerVisible ? 'block' : 'none',
            })
          }, [
            _createVNode(_component_bottom_drawer, { onResizeDrawer: _ctx.handleBottomDrawerResize }, null, 8, ["onResizeDrawer"])
          ], 4)
        ], 512),
        _createElementVNode("div", {
          id: "right-drawer-wrapper",
          class: "right-drawer",
          ref: "rightDrawer",
          style: _normalizeStyle({ width: _ctx.rightWidth + 'px' })
        }, [
          _createVNode(_component_drawer_right, { onResizeDrawer: _ctx.handleRightDrawerResize }, null, 8, ["onResizeDrawer"])
        ], 4)
      ])), [
        [_directive_loading, _ctx.isLoading]
      ])
    ])
  ], 2))
}