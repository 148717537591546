
import { cloneDeep, debounce } from 'lodash';
import {
  registerSchema,
  unregisterSchema,
  validate,
  OutputUnit,
  SchemaObject,
} from '@hyperjump/json-schema/draft-07';
// @ts-ignore
import { BASIC } from '@hyperjump/json-schema/experimental';
import {
  computed,
  defineComponent,
  ref,
  Ref,
  onUnmounted,
  watch,
  PropType,
  defineAsyncComponent,
  toRaw,
} from 'vue';
import {
  InteractivityHookStage,
  RequestJSONSchema,
  SimpleResponse,
  WidgetType,
} from './types';
import GenericField from './GenericField.vue';
import GenericFormTable from './GenericFormTable.vue';
import KvTable from './KeyValueTable.vue';
import { inputTypeFromSchema } from './parse';
import { propertyHasHooks, canSchemaBeRendered } from './utils';

// interface Props {
//   schema: RequestJSONSchema;
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   data: any;
//   hook?: InteractivityHookStage;
//   centered?: boolean;
//   skipValidation?: boolean;
//   renderLabels?: boolean;
//   forceReadOnly?: boolean;
//   recursiveErrors: // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   ErrorObject<string, Record<string, any>, unknown>[] | null | undefined;
// }

export default defineComponent({
  name: 'GenericForm',
  props: {
    schema: {
      type: Object as PropType<RequestJSONSchema>,
      required: true,
    },
    data: {
      type: Object as PropType<SimpleResponse>,
      required: true,
    },
    hook: String,
    centered: Boolean,
    skipValidation: Boolean,
    renderLabels: {
      type: Boolean,
      default: true,
    },
    forceReadOnly: {
      type: Boolean,
      default: false,
    },
    fieldKey: {
      type: String,
      default: 'id',
    },
    recursiveErrors: {
      type: Object as PropType<OutputUnit>,
      default: () => [],
    },
  },
  components: {
    GenericField,
    GenericFormTable,
    KvTable,
    GenericFormGroup: defineAsyncComponent(
      () => import('./GenericFormGroup.vue')
    ),
  },
  setup(props, { emit }) {
    let responseData: SimpleResponse = {};
    async function sendResponse() {
      let valid: OutputUnit = {
        valid: true,
        errors: [],
        instanceLocation: '',
        absoluteKeywordLocation: '',
        keyword: '',
      };
      if (!props.skipValidation && props.schema.$id) {
        valid = await validate(props.schema.$id, responseData, BASIC);
      }
      if (valid.valid) {
        emit('update:responseData', responseData);
      }
      console.log('schema validation errors: ', valid);
      errors.value = valid;
      emit('error:responseData', errors.value);
    }

    // turns out validation gets a bit expensive when there are a lot
    // of fields. Debouncing it to prevent UI lockups when many forms
    // with many fields are on the current page.
    const debouncedSendResponse = debounce(sendResponse, 500);

    onUnmounted(() => {
      responseData = {};
      if (props.schema.$id) {
        unregisterSchema(props.schema.$id);
      }
    });

    const errors: Ref<OutputUnit | undefined> = ref(props.recursiveErrors);

    function canRender(schema: RequestJSONSchema): boolean {
      return canSchemaBeRendered(schema);
    }

    function hooksMatch(field: RequestJSONSchema): boolean {
      if (!props.hook) {
        return true;
      }
      switch (props.hook) {
        case InteractivityHookStage.INTERACTIVITY_HOOK_POST_CREATE: {
          return !!(
            field['ui:options']?.['hooks:postCreate'] ||
            field['ui:hooks:postCreate']
          );
        }
        case InteractivityHookStage.INTERACTIVITY_HOOK_PRE_BATCH: {
          return !!(
            field['ui:options']?.['hooks:preBatch'] ||
            field['ui:hooks:preBatch']
          );
        }
        case InteractivityHookStage.INTERACTIVITY_HOOK_POST_BATCH: {
          return !!(
            field['ui:options']?.['hooks:postBatch'] ||
            field['ui:hooks:postBatch']
          );
        }
        case InteractivityHookStage.INTERACTIVITY_HOOK_PRE_SCHEDULE: {
          return !!(
            field['ui:options']?.['hooks:preSchedule'] ||
            field['ui:hooks:preSchedule']
          );
        }
        case InteractivityHookStage.INTERACTIVITY_HOOK_POST_SCHEDULE: {
          return !!(
            field['ui:options']?.['hooks:postSchedule'] ||
            field['ui:hooks:postSchedule']
          );
        }
        default:
        case InteractivityHookStage.INTERACTIVITY_HOOK_PRE_CREATE: {
          return !!(
            field['ui:options']?.['hooks:preCreate'] ||
            field['ui:hooks:preCreate'] ||
            !propertyHasHooks(field)
          );
        }
      }
    }

    const fields = computed(() => {
      const schema = cloneDeep(props.schema.properties || {});
      delete schema.required;
      return schema;
    });

    const inputType = (field: RequestJSONSchema) => {
      return inputTypeFromSchema(field);
    };

    watch(
      () => props.data,
      () => {
        if (props.data) {
          responseData = cloneDeep(props.data);
        }
        debouncedSendResponse();
      },
      { immediate: true }
    );

    watch(
      () => props.schema,
      (newSchema, oldSchema) => {
        if (!props.skipValidation) {
          if (oldSchema?.$id) {
            unregisterSchema(oldSchema.$id);
          }
          if (newSchema.$id) {
            registerSchema(toRaw(newSchema) as SchemaObject, newSchema.$id);
          }
          debouncedSendResponse();
        }
      },
      { immediate: true, deep: true }
    );

    watch(
      () => props.recursiveErrors,
      (newErrors) => {
        if (props.skipValidation) {
          errors.value = newErrors;
        }
      }
    );

    return {
      canRender,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateField(name: string, val: any) {
        responseData[name] = val;
        debouncedSendResponse();
      },
      getData(name: string) {
        if (
          props.data &&
          props.data[name] !== null &&
          props.data[name] !== undefined
        ) {
          return props.data[name];
        } else if (
          // @ts-ignore
          fields.value?.[name].default !== null &&
          // @ts-ignore
          fields.value?.[name].default !== undefined
        ) {
          // @ts-ignore
          return fields.value[name].default;
        } else if (
          // @ts-ignore
          fields.value[name].type === 'integer' ||
          // @ts-ignore
          fields.value[name].type === 'number'
        ) {
          // @ts-ignore
          if (fields.value[name].minimum) {
            // @ts-ignore
            return fields.value[name].minimum;
          } else {
            return 0;
          }
          // @ts-ignore
        } else if (fields.value[name].type === 'boolean') {
          return false;
        } else if (
          // @ts-ignore
          fields.value[name].type === 'string' &&
          !props.schema.required?.includes(name)
        ) {
          // special case for a string. IF the field is required, then let the null pass down
          // if it's not required, then pass down an empty string otherwise validation will fail
          // with "<field> must be a string" even though it isn't required.
          return '';
        }
        return null;
      },
      fieldRequired(name: string) {
        return !!props.schema.required?.includes(name);
      },
      fields,
      inputType,
      WidgetType,
      errors,
      hooksMatch,
    };
  },
});
