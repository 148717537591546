import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47ce06f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "generic-form-container" }
const _hoisted_2 = { class: "generic-form-display" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_generic_form_table = _resolveComponent("generic-form-table")!
  const _component_kv_table = _resolveComponent("kv-table")!
  const _component_generic_form_group = _resolveComponent("generic-form-group")!
  const _component_generic_field = _resolveComponent("generic-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field, name) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `${name}${_ctx.fieldKey}`
        }, [
          (_ctx.hooksMatch(field))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (
              (_ctx.inputType(field) === _ctx.WidgetType.TABLE ||
                (field.type === 'array' && field.items.type === 'object')) &&
              _ctx.canRender(field)
            )
                  ? (_openBlock(), _createBlock(_component_generic_form_table, {
                      key: 0,
                      "table-field": field.items,
                      "table-name": name,
                      "initial-values": _ctx.getData(name),
                      "onUpdate:value": ($event: any) => (_ctx.updateField(name, $event)),
                      errors: _ctx.errors,
                      "force-read-only": _ctx.forceReadOnly
                    }, null, 8, ["table-field", "table-name", "initial-values", "onUpdate:value", "errors", "force-read-only"]))
                  : (field.type === 'object' && !field.properties)
                    ? (_openBlock(), _createBlock(_component_kv_table, {
                        key: 1,
                        field: field,
                        "initial-values": _ctx.getData(name),
                        "onUpdate:value": ($event: any) => (_ctx.updateField(name, $event)),
                        errors: _ctx.errors,
                        "force-read-only": _ctx.forceReadOnly
                      }, null, 8, ["field", "initial-values", "onUpdate:value", "errors", "force-read-only"]))
                    : (
              (field.type === 'object' && field.properties) ||
              _ctx.inputType(field) === _ctx.WidgetType.SECTION ||
              _ctx.inputType(field) === _ctx.WidgetType.GROUP ||
              _ctx.inputType(field) === _ctx.WidgetType.JSON_FILE
            )
                      ? (_openBlock(), _createBlock(_component_generic_form_group, {
                          key: 2,
                          schema: field,
                          "initial-values": _ctx.getData(name),
                          name: name,
                          "field-key": _ctx.fieldKey,
                          "onUpdate:value": ($event: any) => (_ctx.updateField(name, $event)),
                          errors: _ctx.errors,
                          "force-read-only": _ctx.forceReadOnly
                        }, null, 8, ["schema", "initial-values", "name", "field-key", "onUpdate:value", "errors", "force-read-only"]))
                      : (_openBlock(), _createBlock(_component_generic_field, {
                          key: 3,
                          name: name,
                          field: field,
                          "render-label": _ctx.renderLabels,
                          "initial-value": _ctx.getData(name),
                          "onUpdate:value": ($event: any) => (_ctx.updateField(name, $event)),
                          errors: _ctx.errors,
                          required: _ctx.fieldRequired(name),
                          "force-read-only": _ctx.forceReadOnly,
                          raw: !_ctx.canRender(field),
                          class: _normalizeClass({ hidden: _ctx.inputType(field) === _ctx.WidgetType.NONE })
                        }, null, 8, ["name", "field", "render-label", "initial-value", "onUpdate:value", "errors", "required", "force-read-only", "raw", "class"]))
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}